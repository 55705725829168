/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * taskreports.api
 * task list api
 * OpenAPI spec version: 1.0.0
 */
import type { BodyType } from '../../services/api-client-instance'
import { customInstance } from '../../services/api-client-instance'

export type GetSiteSubscribedParams = {
  site: Uuid
}

export type GetWhoWasThereParams = {
  startDate: string
  endDate: string
  siteId: Uuid
  resolveUsers?: boolean
}

export type GetUserBySiteParams = {
  siteId: Uuid
  userId: Uuid
}

export type GetUsersByOrgParams = {
  offset?: number
  limit?: number
}

export type GetUsersBySiteParams = {
  siteId: Uuid
  offset?: number
  limit?: number
}

export type CreateGlobalTemplateBody = {
  organizationId: Uuid
  siteId: Uuid
}

export type GetMockPNsParams = {
  pushtoken: InputText
}

export type GetMockSMSsParams = {
  phone: InputString
}

export type GetMockEmailsParams = {
  email: Email
}

export type GetTemplateReportAggregationDataParams = {
  templateReportId: DoubleUUID
  siteId: Uuid
  startDate: string
  endDate: string
}

export type GetTemplateReportDailyDataParams = {
  templateReportId: DoubleUUID
  siteId: Uuid
  startDate: string
}

export type DeleteTemplateReportParams = {
  id: DoubleUUID
}

export type GetTemplateReportParams = {
  id: DoubleUUID
  siteId: Uuid
}

export type GetTemplateReportsParams = {
  siteId: Uuid
}

export type DeleteTaskParams = {
  id: DoubleUUID
}

export type GetTaskParams = {
  id: DoubleUUID
  resolvePositions?: boolean
  resolveTaskGroups?: boolean
  resolveEscalations?: boolean
}

export type GetAnytimeTasksParams = {
  siteId: Uuid
}

export type GetTasksParams = {
  siteId: Uuid
  offset?: number
  limit?: number
  includesTaskGroupId?: DoubleUUID
  resolvePositions?: boolean
  resolveTaskGroups?: boolean
  resolveEscalations?: boolean
}

export type DeleteTaskGroupParams = {
  id: DoubleUUID
}

export type GetTaskGroupParams = {
  id: DoubleUUID
}

export type GetTaskGroupsParams = {
  siteId: Uuid
  offset?: number
  limit?: number
}

export type SuperuserSignupVerifyConfirm200 = {
  userApproved: boolean
}

export type SuperuserSignupVerifyConfirmBody = {
  token: string
}

/**
 * @nullable
 */
export type SuperuserSignupVerify200 =
  | (typeof SuperuserSignupVerify200)[keyof typeof SuperuserSignupVerify200]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SuperuserSignupVerify200 = {} as const

export type SuperuserSignupVerifyParams = {
  token: string
}

export type GetSiteTagTypesParams = {
  siteId: Uuid
}

export type GetSiteTagsWithTypeParams = {
  siteId?: Uuid
  siteTagTypeId?: Uuid
}

export type ModTemplateStatusBody = {
  siteId: Uuid
  status?: SiteTemplateStatus
}

export type DeleteSiteParams = {
  id: Uuid
}

export type GetSiteParams = {
  id: Uuid
}

export type GetSitesParams = {
  offset?: number
  limit?: number
}

export type DeleteScheduledShiftParams = {
  id: DoubleUUID
}

export type GetScheduledShiftParams = {
  id: DoubleUUID
}

export type GetScheduledShiftsParams = {
  startDate: string
  endDate: string
  site: Uuid
  limit?: number
  offset?: number
}

export type RollingStatsBreakdownParams = {
  timeframe: AggregationTimeframe
  siteId: Uuid
  taskStatus: CompletionStatus
  orgTemplateId?: Uuid
}

export type RollingStatsReportParams = {
  timeframe: AggregationTimeframe
  reverseOrder?: boolean
  offset?: number
  limit?: number
  orgTemplateId?: Uuid
}

export type ConductReportParams = {
  startDate: string
  endDate: string
  siteId: Uuid
}

export type DeletePositionParams = {
  id: DoubleUUID
}

export type GetPositionParams = {
  id: DoubleUUID
}

export type GetPositionsParams = {
  siteId: Uuid
  offset?: number
  limit?: number
  resolvePositions?: boolean
}

export type AllOrgTemplatesParams = {
  site: Uuid
  offset?: number
  limit?: number
}

export type PushTokenTestBody = {
  token?: string
}

export type GetEscOccurrencesParams = {
  startDate: string
  endDate: string
  site: Uuid
  isResolved?: boolean
  offset?: number
  limit?: number
}

export type DeleteEscalationParams = {
  id: DoubleUUID
}

export type GetEscalationParams = {
  id: DoubleUUID
}

export type GetEscalationsParams = {
  siteId: Uuid
  offset?: number
  limit?: number
}

export type GetEmailPrefsParams = {
  id: Uuid
}

export type DeleteContactParams = {
  id: Uuid
}

export type GetContactParams = {
  id: Uuid
}

export type GetContactsBySiteParams = {
  siteId: Uuid
  includeAdminContacts?: boolean
  offset?: number
  limit?: number
}

export type AllContactsParams = {
  offset?: number
  limit?: number
}

export type CreateAnytimeCompletionBody = {
  taskId: DoubleUUID
}

export type GetAllCompletionsParams = {
  startDate: string
  endDate: string
  site: Uuid
  limit?: number
  offset?: number
  showOnlySkipped?: boolean
  showOnlyMissed?: boolean
  status?: CompletionStatus
  showForTaskId?: DoubleUUID
  showForPositionId?: DoubleUUID
  showForTaskGroupId?: DoubleUUID
  showForUserId?: Uuid
}

export type GetUserCompletionsParams = {
  endDate: string
  siteId: Uuid
  taskId?: DoubleUUID
}

export type LoadCommentsByIdsParams = {
  siteId: Uuid
  commentIds: string[]
}

/**
 * @nullable
 */
export type CheckForBotToken200 = BotTokenIssue | null

export type CheckForBotTokenParams = {
  botTargetKey: InputString
}

export type GetInvoicesParams = {
  offset: number
}

export type GetCurrentPaymentMethod200AnyOf = { [key: string]: unknown }

export type GetCurrentPaymentMethod200 =
  | CardDetails
  | GetCurrentPaymentMethod200AnyOf

export type ModifySubscriptionStatusBody = {
  activate: boolean
}

export type SetPaymentMethodBody = {
  paymentMethodId: string
}

export type LoginViaWallboardBody = {
  token: Uuid
  userId: Uuid
}

export type WallboardLoginUsers200Item = {
  id: Uuid
  name: string
}

export type WallboardLoginUsersParams = {
  token: Uuid
}

export type WallboardGetLoginTokenParams = {
  siteId: Uuid
}

export type GetSelfParams = {
  resolveSites?: boolean
}

export type SiteSnapshotForAdminParams = {
  siteId: Uuid
}

export type SiteSnapshotForUserParams = {
  siteId: Uuid
}

export type GetGroupedCompletionsParams = {
  startDate: string
  endDate: string
  siteId: Uuid
  isMissed: boolean
}

export interface AdminSetPassword {
  password: string
  siteId: Uuid
  userId: Uuid
}

export interface ModifyUserDetails {
  email: Email
  name?: InputString
  phone?: InputString
  siteId: Uuid
}

export type UserAddRemoveOptions =
  (typeof UserAddRemoveOptions)[keyof typeof UserAddRemoveOptions]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAddRemoveOptions = {
  add: 'add',
  remove: 'remove',
} as const

export interface UserModGlobalAdmin {
  action: UserAddRemoveOptions
  email: Email
}

export interface UserModSiteAdmin {
  action: UserAddRemoveOptions
  email: Email
  privLevel: PrivLevel
  siteId: Uuid
}

export interface UserMod {
  action: UserAddRemoveOptions
  email: Email
  siteId: Uuid
}

export interface UserModPosition {
  action: UserAddRemoveOptions
  email: Email
  positionId: DoubleUUID
  siteId: Uuid
}

export interface UserOnsite {
  onsiteEnd?: string
  onsiteStart: string
  onsiteTimedOut?: boolean
  siteId: Uuid
  user?: User
  userId: Uuid
}

export interface UserList {
  limit: number
  offset: number
  total: number
  users: User[]
}

/**
 * Construct a type with a set of properties K of type T
 */
export interface RecordStringString {
  [key: string]: unknown
}

export type ResendPayloadDataData = {
  created_at: string
  email_id: string
  from: string
  headers?: RecordStringString
  subject: string
  tags?: RecordStringString
  to: string[]
}

export type ResendPayloadType =
  (typeof ResendPayloadType)[keyof typeof ResendPayloadType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResendPayloadType = {
  emailsent: 'email.sent',
  emaildelivered: 'email.delivered',
  emaildelivery_delayed: 'email.delivery_delayed',
  emailcomplained: 'email.complained',
  emailbounced: 'email.bounced',
  emailopened: 'email.opened',
  emailclicked: 'email.clicked',
} as const

export interface ResendPayloadData {
  created_at: string
  data: ResendPayloadDataData
  type: ResendPayloadType
}

export interface MockPN {
  body: string
  jsonData?: string
  title: string
  tokenData: PushTokenData
}

export interface MockSMS {
  body: string
  to: string[]
}

export interface MockEmail {
  body: string
  subject: string
  to: Email[]
}

export interface TemplateReportDailyData {
  comments: Comment[]
  completions: Completion[]
  escOccurrences: EscOccurrence[]
}

export interface ModifyTemplateReportData {
  id: DoubleUUID
  name: string
  taskIds: DoubleUUID[]
}

export interface CreateTemplateReportData {
  name: string
  siteId: Uuid
  taskIds: DoubleUUID[]
}

export interface TemplateReport {
  cts?: string
  id: DoubleUUID
  mts?: string
  name: string
  organizationId: Uuid
  orgTemplateData?: OrgTemplateData
  siteId: Uuid
  taskIds: DoubleUUID[]
}

/**
 * @maxLength 250
 */
export type InputStringLong = string

export interface ModifyTask {
  advancedViewing?: boolean
  canSkip?: boolean
  description?: InputStringLong
  difficulty?: TaskDifficulty
  escalationIds?: DoubleUUID[]
  id: DoubleUUID
  isAnyTime?: boolean
  missActions?: TaskMissActionUpload[]
  multiChoice?: MultiChoiceQuestion[]
  onCompleteEmailPositionIds?: DoubleUUID[]
  orgTemplateData?: OrgTemplateData
  positionIds?: DoubleUUID[]
  scheduleStrings?: InputText[]
  taskGroupIds?: DoubleUUID[]
  title?: InputString
  verification?: TaskVerification
  verificationSecret?: InputText
}

export interface CreateTask {
  advancedViewing?: boolean
  canSkip: boolean
  description: InputStringLong
  difficulty: TaskDifficulty
  escalationIds?: DoubleUUID[]
  isAnyTime?: boolean
  missActions: TaskMissActionUpload[]
  multiChoice?: MultiChoiceQuestion[]
  onCompleteEmailPositionIds: DoubleUUID[]
  orgTemplateData?: OrgTemplateData
  positionIds: DoubleUUID[]
  scheduleStrings: InputText[]
  siteId: Uuid
  taskGroupIds?: DoubleUUID[]
  title: InputString
  verification: TaskVerification
  verificationSecret?: InputText
}

export interface CloneTaskReq {
  newTitle: string
  siteId: Uuid
  taskId: DoubleUUID
}

export interface TaskList {
  limit: number
  offset: number
  tasks: Task[]
  total: number
}

export type TaskDifficulty =
  (typeof TaskDifficulty)[keyof typeof TaskDifficulty]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskDifficulty = {
  easy: 'easy',
  medium: 'medium',
  hard: 'hard',
} as const

export interface TimeRange {
  endTimeUtcHour: HoursOfTheDay
  startTimeUtcHour: HoursOfTheDay
}

export type ActionType = (typeof ActionType)[keyof typeof ActionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionType = {
  notification: 'notification',
  reminder: 'reminder',
} as const

export interface TaskMissActionUpload {
  actionType: ActionType
  firstOcurranceDelaySeconds: number
  inStoreOnly: boolean
  maxOccurrences: number
  positionIds: DoubleUUID[]
  pushTypes: PushType[]
  subsequentRecurrenceDelaySeconds: number
  timeRange?: TimeRange
}

export interface TaskMissAction {
  actionType: ActionType
  firstOcurranceDelaySeconds: number
  inStoreOnly: boolean
  maxOccurrences: number
  positionIds: DoubleUUID[]
  positions?: Position[]
  pushTypes: PushType[]
  subsequentRecurrenceDelaySeconds: number
  timeRange?: TimeRange
}

export type TaskVerification =
  (typeof TaskVerification)[keyof typeof TaskVerification]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskVerification = {
  selfReport: 'selfReport',
  qrCode: 'qrCode',
  picture: 'picture',
  nfc: 'nfc',
  nfcAndPicture: 'nfcAndPicture',
  qrCodeAndPicture: 'qrCodeAndPicture',
} as const

export interface Task {
  advancedViewing?: boolean
  canSkip: boolean
  cts?: string
  description: string
  difficulty: TaskDifficulty
  escalationIds: DoubleUUID[]
  escalations?: Escalation[]
  id: DoubleUUID
  isAnyTime?: boolean
  missActions: TaskMissAction[]
  mts?: string
  multiChoice?: MultiChoiceQuestion[]
  onCompleteEmailPositionIds: DoubleUUID[]
  organizationId: Uuid
  orgTemplateData?: OrgTemplateData
  positionIds: DoubleUUID[]
  positions?: Position[]
  scheduleStrings: string[]
  siteId: Uuid
  taskGroupIds: DoubleUUID[]
  taskGroups?: TaskGroup[]
  title: string
  verification: TaskVerification
  verificationSecret?: string
}

export interface ModifyTaskGroup {
  details?: InputString
  id: DoubleUUID
  name?: InputString
  orgTemplateData?: OrgTemplateData
  uiData?: TaskgroupUiData
}

export interface CreateTaskGroup {
  details: InputString
  name: InputString
  orgTemplateData?: OrgTemplateData
  siteId: Uuid
  uiData?: TaskgroupUiData
}

export interface TaskGroupList {
  limit: number
  offset: number
  taskGroups: TaskGroup[]
  total: number
}

export interface AppVersionInfo {
  minNoWarningAppVersion: string
  minUsableAppVersion: string
}

export interface SuperuserSetPricingBody {
  organizationId: Uuid
  pricing: SiteBillingType
  superuserPassword: InputString
  superuserUsername: InputString
}

export interface SuperuserGetOwnerJwtBody {
  organizationId: Uuid
  superuserPassword: InputString
  superuserUsername: InputString
}

export interface SuperuserGetAllOrganizationsBody {
  superuserPassword: InputString
  superuserUsername: InputString
}

export interface SiteTagType {
  cts?: string
  id: Uuid
  mts?: string
  title: string
}

export interface SiteTagWithType {
  cts?: string
  id: Uuid
  mts?: string
  siteTagType: SiteTagType
  siteTagTypeId: Uuid
  title: string
}

/**
 * @nullable
 */
export type ModifySiteStoreHours = StoreHours | null

export interface ModifySite {
  address?: InputText
  emailToCCOnContactRequests?: Email[]
  id: Uuid
  name?: InputString
  siteTagIds?: InputString[]
  /** @nullable */
  storeHours?: ModifySiteStoreHours
  timezone?: Timezone
  wallboardSettings?: WallboardSettings
  zipCode?: InputString
}

/**
 * @nullable
 */
export type CreateSiteStoreHours = StoreHours | null

export interface CreateSite {
  address: InputText
  emailToCCOnContactRequests?: Email[]
  name: InputString
  siteTagIds?: InputString[]
  /** @nullable */
  storeHours?: CreateSiteStoreHours
  timezone: Timezone
  wallboardSettings?: WallboardSettings
  zipCode: InputString
}

export interface SiteList {
  limit: number
  offset: number
  sites: Site[]
  total: number
}

export interface ShiftBreak {
  durationMin: number
  mandatory: boolean
  paid: boolean
  startsAfterShiftHrs: number
  type: string
}

export interface ModifyScheduledShift {
  assignmentId?: Uuid
  breaks?: ShiftBreak[]
  color?: string
  endDate?: string
  id: DoubleUUID
  notes?: string
  positionId?: Uuid
  startDate?: string
  title?: string
  userId?: Uuid
}

export interface CreateScheduledShift {
  assignmentId?: Uuid
  breaks: ShiftBreak[]
  color?: string
  endDate: string
  notes?: string
  positionId: Uuid
  siteId: Uuid
  startDate: string
  title: string
  userId?: Uuid
}

export interface ScheduledShift {
  assignmentId?: Uuid
  breaks: ShiftBreak[]
  color?: string
  cts?: string
  endDate: string
  hasUnpublishedChanges: boolean
  id: DoubleUUID
  mts?: string
  notes?: string
  /** Open=true supports a feature for Users to "pick-up" a Shift */
  organizationId: Uuid
  positionId: Uuid
  /** When this ScheduledShift is published this is set so that any changes/re-publishing can update the correlating Shift also */
  shiftId?: string
  /** When shiftId is set and a User starts a Shift (ie: clock-in) this is set so that no Users can make changes and publish over a started Shift */
  shiftStarted?: boolean
  siteId: Uuid
  startDate: string
  title: string
  userId?: Uuid
}

export interface ScheduledShiftList {
  limit: number
  offset: number
  scheduledShifts: ScheduledShift[]
  total: number
}

export interface TaskGroupWithOccurrenceQty {
  qtyOfOccurrences: number
  taskGroupId: DoubleUUID
  taskGroupName: string
}

export interface TaskWithOccurrenceQty {
  qtyOfOccurrences: number
  taskId: DoubleUUID
  taskName: string
}

export interface RollingStatsBreakdown {
  breakdownByTaskGroup: TaskGroupWithOccurrenceQty[]
  breakdownByTopTenTasks: TaskWithOccurrenceQty[]
}

export interface EscalationInfo {
  averageResolutionSeconds: number
  escalationId: DoubleUUID
  occurrences: number
}

export interface TaskCompletionStats {
  completedTasks: number
  completionPct: number
  missedTasks: number
  skippedTasks: number
}

export type AggregationTimeframe =
  (typeof AggregationTimeframe)[keyof typeof AggregationTimeframe]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AggregationTimeframe = {
  Rolling1Day: 'Rolling1Day',
  Rolling7Day: 'Rolling7Day',
  Rolling30Day: 'Rolling30Day',
} as const

export interface RollingSiteStats {
  aggregationPeriodEndTime: string
  aggregationPeriodStartTime: string
  aggregationTimeframe: AggregationTimeframe
  mostCommonEscalations: EscalationInfo[]
  organizationId: Uuid
  orgTemplateId?: Uuid
  siteId: Uuid
  taskCompletionStatsUnweighted: TaskCompletionStats
  taskCompletionStatsWeighted: TaskCompletionStats
}

export interface RollingSiteStatsList {
  limit: number
  offset: number
  rollingSiteStats: RollingSiteStats[]
  total: number
}

export interface ModifyPosition {
  id: DoubleUUID
  orgTemplateData?: OrgTemplateData
  positionIds?: DoubleUUID[]
  title?: InputString
  unScored?: boolean
}

export interface CreatePosition {
  orgTemplateData?: OrgTemplateData
  positionIds: DoubleUUID[]
  siteId: Uuid
  title: InputString
  unScored?: boolean
}

export interface PositionList {
  limit: number
  offset: number
  positions: Position[]
  total: number
}

export interface UseOrgTemplate {
  orgTemplateId: Uuid
  siteId: Uuid
}

export interface OrgTemplateDisplay {
  id: Uuid
  name: string
  siteTagIds?: string[]
}

export interface OrgTemplateDisplayList {
  limit: number
  offset: number
  orgTemplates: OrgTemplateDisplay[]
  total: number
}

export interface OrganizationEdit {
  maxSecondsToCompleteTasks?: number
  name?: InputString
}

export interface Organization {
  cts?: string
  id: Uuid
  maxSecondsToCompleteTasks?: number
  mts?: string
  name: string
  orgBillingType?: SiteBillingType
  paymentSource: OrgPaymentSource
  shutdown?: Shutdown
  unverified?: boolean
}

export interface UnsubscribeBody {
  siteId: Uuid
}

export type HoursOfTheDay = (typeof HoursOfTheDay)[keyof typeof HoursOfTheDay]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HoursOfTheDay = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
  NUMBER_9: 9,
  NUMBER_10: 10,
  NUMBER_11: 11,
  NUMBER_12: 12,
  NUMBER_13: 13,
  NUMBER_14: 14,
  NUMBER_15: 15,
  NUMBER_16: 16,
  NUMBER_17: 17,
  NUMBER_18: 18,
  NUMBER_19: 19,
  NUMBER_20: 20,
  NUMBER_21: 21,
  NUMBER_22: 22,
  NUMBER_23: 23,
} as const

export interface SubscribeBody {
  hoursUntilExpires?: HoursOfTheDay
  siteId: Uuid
}

export interface GeofenceEventBody {
  location: GeofenceEventBodyLocation
}

export type GeofenceEventBodyLocationExtras = { [key: string]: unknown }

export type GeofenceEventBodyLocationCoords = {
  latitude: number
  longitude: number
}

export type GeofenceEventBodyLocationBattery = {
  is_charging: boolean
  level: number
}

export type GeofenceEventBodyLocation = {
  battery?: GeofenceEventBodyLocationBattery
  coords: GeofenceEventBodyLocationCoords
  event: string
  extras?: GeofenceEventBodyLocationExtras
  geofence?: GeofenceEventBodyLocationGeofence
  is_moving?: boolean
  mock?: boolean
  timestamp: string
}

export type GeofenceActionChoices =
  (typeof GeofenceActionChoices)[keyof typeof GeofenceActionChoices]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GeofenceActionChoices = {
  ENTER: 'ENTER',
  EXIT: 'EXIT',
} as const

export type GeofenceEventBodyLocationGeofence = {
  action?: GeofenceActionChoices
  identifier?: string
}

export interface EmailTestMessage {
  email: Email
  extraMessage?: InputText
  siteId: Uuid
}

export interface EmailEscOccurrence {
  email: Email
  escOccuranceId: DoubleUUID
  extraMessage?: InputText
  siteId: Uuid
}

export interface ResolveEscOccurrence {
  escOccuranceId: DoubleUUID
  multiChoiceResponses?: MultiChoiceResponse[]
  siteId: Uuid
}

export interface TriggerInfo {
  triggerCompletionId?: DoubleUUID
  triggerId: DoubleUUID
  triggerTaskId?: DoubleUUID
}

export interface EscOccurrence {
  commentIds?: Uuid[]
  cts?: string
  escalatingUserId?: Uuid
  escalationId: Uuid
  id: DoubleUUID
  mts?: string
  multiChoice?: MultiChoiceQuestion[]
  organizationId: Uuid
  resolvedDate?: string
  resolvingUserId?: Uuid
  siteId: Uuid
  timestamp: string
  triggeredBy: TriggerInfo
}

export interface EscOccurrenceList {
  escOccurrences: EscOccurrence[]
  limit: number
  offset: number
  total: number
}

export type PushType = (typeof PushType)[keyof typeof PushType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PushType = {
  email: 'email',
  pushNotification: 'pushNotification',
} as const

export interface ModifyEscalation {
  details?: InputString
  id: DoubleUUID
  multiChoice?: MultiChoiceQuestion[]
  name?: InputString
  notifyPositionIds?: DoubleUUID[]
  orgTemplateData?: OrgTemplateData
  pushTypes?: PushType[]
}

export interface CreateEscalation {
  details: InputString
  multiChoice?: MultiChoiceQuestion[]
  name: InputString
  notifyPositionIds: DoubleUUID[]
  orgTemplateData?: OrgTemplateData
  pushTypes: PushType[]
  siteId: Uuid
}

export interface Escalation {
  cts?: string
  details: string
  id: DoubleUUID
  mts?: string
  multiChoice?: MultiChoiceQuestion[]
  name: string
  notifyPositionIds: DoubleUUID[]
  organizationId: Uuid
  orgTemplateData?: OrgTemplateData
  pushTypes: PushType[]
  siteId: Uuid
}

export interface EscalationList {
  escalations: Escalation[]
  limit: number
  offset: number
  total: number
}

export interface EmailPreferenceOptions {
  receiveEmails: boolean
}

export interface ModEmailPreferences {
  id: Uuid
  preferences: EmailPreferenceOptions
}

export interface EmailPreferences {
  cts?: string
  email: Email
  id: Uuid
  mts?: string
  preferences: EmailPreferenceOptions
}

export interface ModifyContact {
  adminOnly?: boolean
  description?: string
  email?: string
  id: Uuid
  isOrgWide?: boolean
  phone?: string
  siteIds: Uuid[]
  title?: string
}

export interface CreateContact {
  adminOnly?: boolean
  description: string
  email?: string
  isOrgWide?: boolean
  phone?: string
  siteIds: Uuid[]
  title: string
}

export interface Contact {
  adminOnly?: boolean
  cts?: string
  description: string
  email?: string
  id: Uuid
  isOrgWide?: boolean
  mts?: string
  organizationId: Uuid
  phone?: string
  siteIds: Uuid[]
  title: string
}

export interface ContactList {
  contacts: Contact[]
  limit: number
  offset: number
  total: number
}

export interface EvaluateCompletion {
  completionId: DoubleUUID
  note?: InputText
  sentiment: EvaluationSentiment
  siteId: Uuid
}

export interface MultiChoiceResponse {
  answerKey: string
  questionKey: string
}

export interface MarkTaskComplete {
  completionId: DoubleUUID
  multiChoiceResponses?: MultiChoiceResponse[]
  skipped?: boolean
  triggerEscalation?: DoubleUUID
  triggerMultiEscalation?: DoubleUUID[]
  userNote?: InputText
  verificationFileKeys?: InputString[]
}

export interface CompletionList {
  completions: Completion[]
  limit: number
  offset: number
  total: number
}

export type PicUploadFileTypes =
  (typeof PicUploadFileTypes)[keyof typeof PicUploadFileTypes]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PicUploadFileTypes = {
  jpg: 'jpg',
  png: 'png',
} as const

export interface CompletionFileUploadRequest {
  completionId: DoubleUUID
  fileExtension: PicUploadFileTypes
}

export interface CompletionFileUploadRespnse {
  key: string
  url: string
}

export type LanguageCode = (typeof LanguageCode)[keyof typeof LanguageCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LanguageCode = {
  aa: 'aa',
  ab: 'ab',
  ae: 'ae',
  af: 'af',
  ak: 'ak',
  am: 'am',
  an: 'an',
  ar: 'ar',
  as: 'as',
  av: 'av',
  ay: 'ay',
  az: 'az',
  ba: 'ba',
  be: 'be',
  bg: 'bg',
  bh: 'bh',
  bi: 'bi',
  bm: 'bm',
  bn: 'bn',
  bo: 'bo',
  br: 'br',
  bs: 'bs',
  ca: 'ca',
  ce: 'ce',
  ch: 'ch',
  co: 'co',
  cr: 'cr',
  cs: 'cs',
  cu: 'cu',
  cv: 'cv',
  cy: 'cy',
  da: 'da',
  de: 'de',
  dv: 'dv',
  dz: 'dz',
  ee: 'ee',
  el: 'el',
  en: 'en',
  eo: 'eo',
  es: 'es',
  et: 'et',
  eu: 'eu',
  fa: 'fa',
  ff: 'ff',
  fi: 'fi',
  fj: 'fj',
  fo: 'fo',
  fr: 'fr',
  fy: 'fy',
  ga: 'ga',
  gd: 'gd',
  gl: 'gl',
  gn: 'gn',
  gu: 'gu',
  gv: 'gv',
  ha: 'ha',
  he: 'he',
  hi: 'hi',
  ho: 'ho',
  hr: 'hr',
  ht: 'ht',
  hu: 'hu',
  hy: 'hy',
  hz: 'hz',
  ia: 'ia',
  id: 'id',
  ie: 'ie',
  ig: 'ig',
  ii: 'ii',
  ik: 'ik',
  io: 'io',
  is: 'is',
  it: 'it',
  iu: 'iu',
  ja: 'ja',
  jv: 'jv',
  ka: 'ka',
  kg: 'kg',
  ki: 'ki',
  kj: 'kj',
  kk: 'kk',
  kl: 'kl',
  km: 'km',
  kn: 'kn',
  ko: 'ko',
  kr: 'kr',
  ks: 'ks',
  ku: 'ku',
  kv: 'kv',
  kw: 'kw',
  ky: 'ky',
  la: 'la',
  lb: 'lb',
  lg: 'lg',
  li: 'li',
  ln: 'ln',
  lo: 'lo',
  lt: 'lt',
  lu: 'lu',
  lv: 'lv',
  mg: 'mg',
  mh: 'mh',
  mi: 'mi',
  mk: 'mk',
  ml: 'ml',
  mn: 'mn',
  mr: 'mr',
  ms: 'ms',
  mt: 'mt',
  my: 'my',
  na: 'na',
  nb: 'nb',
  nd: 'nd',
  ne: 'ne',
  ng: 'ng',
  nl: 'nl',
  nn: 'nn',
  no: 'no',
  nr: 'nr',
  nv: 'nv',
  ny: 'ny',
  oc: 'oc',
  oj: 'oj',
  om: 'om',
  or: 'or',
  os: 'os',
  pa: 'pa',
  pi: 'pi',
  pl: 'pl',
  ps: 'ps',
  pt: 'pt',
  qu: 'qu',
  rm: 'rm',
  rn: 'rn',
  ro: 'ro',
  ru: 'ru',
  rw: 'rw',
  sa: 'sa',
  sc: 'sc',
  sd: 'sd',
  se: 'se',
  sg: 'sg',
  si: 'si',
  sk: 'sk',
  sl: 'sl',
  sm: 'sm',
  sn: 'sn',
  so: 'so',
  sq: 'sq',
  sr: 'sr',
  ss: 'ss',
  st: 'st',
  su: 'su',
  sv: 'sv',
  sw: 'sw',
  ta: 'ta',
  te: 'te',
  tg: 'tg',
  th: 'th',
  ti: 'ti',
  tk: 'tk',
  tl: 'tl',
  tn: 'tn',
  to: 'to',
  tr: 'tr',
  ts: 'ts',
  tt: 'tt',
  tw: 'tw',
  ty: 'ty',
  ug: 'ug',
  uk: 'uk',
  ur: 'ur',
  uz: 'uz',
  ve: 've',
  vi: 'vi',
  vo: 'vo',
  wa: 'wa',
  wo: 'wo',
  xh: 'xh',
  yi: 'yi',
  yo: 'yo',
  za: 'za',
  zh: 'zh',
  zu: 'zu',
} as const

export type CommentAttachType =
  (typeof CommentAttachType)[keyof typeof CommentAttachType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommentAttachType = {
  escOccurrence: 'escOccurrence',
  completion: 'completion',
} as const

export interface CommentAttachTarget {
  attachType: CommentAttachType
  id: DoubleUUID
}

export interface CreateCommentInput {
  attachTarget?: CommentAttachTarget
  fileKeys?: string[]
  siteId?: Uuid
  userNote: string
}

export interface Comment {
  cts?: string
  fileKeys?: string[]
  fileUrls?: string[]
  id: Uuid
  mts?: string
  organizationId: Uuid
  userId: Uuid
  userNote: string
}

export interface Brand {
  id: string
  name: string
}

/**
 * @maxLength 150
 */
export type InputString = string

export interface CreateBotInput {
  botTargetKey: InputString
  siteId: Uuid
}

export interface BotTokenIssue {
  siteId: Uuid
  token: string
}

export type InvoiceStatus = (typeof InvoiceStatus)[keyof typeof InvoiceStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceStatus = {
  Open: 'Open',
  Paid: 'Paid',
  Delinquent: 'Delinquent',
  Void: 'Void',
} as const

export interface Invoice {
  cts?: string
  day: number
  id: Uuid
  invoiceNumber: string
  month: number
  mts?: string
  nonPaidSites: Uuid[]
  organizationId: Uuid
  paidDate?: string
  paidSites: Uuid[]
  perSiteCents: number
  status: InvoiceStatus
  tempNonPaidSites: Uuid[]
  totalCents: number
  viewLink?: string
  year: number
}

export interface CardDetails {
  brand: string
  last4: string
}

export type OrgPaymentSource =
  (typeof OrgPaymentSource)[keyof typeof OrgPaymentSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrgPaymentSource = {
  CreditCard: 'CreditCard',
  CustomInvoice: 'CustomInvoice',
} as const

export interface OrgBillingDetails {
  adminDiscount: boolean
  email?: string
  nextBillingDate: string
  nonBilledSiteIds: Uuid[]
  organizationId: Uuid
  organizationName: string
  paymentSource?: OrgPaymentSource
  perSiteRateCents?: number
  siteIds: Uuid[]
  tempNonBilledSiteIds: Uuid[]
  totalRateCents?: number
}

export interface ConfirmPasswordReset {
  password: string
  token: string
}

export type PushTokenTypes =
  (typeof PushTokenTypes)[keyof typeof PushTokenTypes]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PushTokenTypes = {
  FCM: 'FCM',
  EXPO: 'EXPO',
} as const

export interface PushTokenData {
  tokenType: PushTokenTypes
  tokenValue: string
}

export interface LoginSuccess {
  sessionKey: Uuid
  siteId?: Uuid
  token: string
  userId: Uuid
}

export type SiteGeolocation = {
  lat: number
  lng: number
}

export type ShutdownState = (typeof ShutdownState)[keyof typeof ShutdownState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShutdownState = {
  Soft: 'Soft',
  Hard: 'Hard',
} as const

export type ShutdownReason =
  (typeof ShutdownReason)[keyof typeof ShutdownReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShutdownReason = {
  Billing: 'Billing',
  User: 'User',
  Admin: 'Admin',
} as const

export interface Shutdown {
  reason: ShutdownReason
  state: ShutdownState
  ts: string
}

export interface WallboardSettings {
  qrCodeSignIn?: boolean
  showUserScores?: boolean
}

export interface AppliedOrgTemplate {
  id: Uuid
  ts: string
}

export type SiteTemplateStatus =
  (typeof SiteTemplateStatus)[keyof typeof SiteTemplateStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SiteTemplateStatus = {
  OpenTemplate: 'OpenTemplate',
  LockedTemplate: 'LockedTemplate',
} as const

/**
 * @maxLength 1000
 */
export type InputText = string

export interface StoreHours {
  storeClosingRules: InputText[]
  storeOpeningRules: InputText[]
}

export type Timezone = (typeof Timezone)[keyof typeof Timezone]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Timezone = {
  'Africa/Algiers': 'Africa/Algiers',
  'Atlantic/Cape_Verde': 'Atlantic/Cape_Verde',
  'Africa/Ndjamena': 'Africa/Ndjamena',
  'Africa/Abidjan': 'Africa/Abidjan',
  'Africa/Bamako': 'Africa/Bamako',
  'Africa/Banjul': 'Africa/Banjul',
  'Africa/Conakry': 'Africa/Conakry',
  'Africa/Dakar': 'Africa/Dakar',
  'Africa/Freetown': 'Africa/Freetown',
  'Africa/Lome': 'Africa/Lome',
  'Africa/Nouakchott': 'Africa/Nouakchott',
  'Africa/Ouagadougou': 'Africa/Ouagadougou',
  'Atlantic/St_Helena': 'Atlantic/St_Helena',
  'Africa/Cairo': 'Africa/Cairo',
  'Africa/Accra': 'Africa/Accra',
  'Africa/Bissau': 'Africa/Bissau',
  'Africa/Nairobi': 'Africa/Nairobi',
  'Africa/Addis_Ababa': 'Africa/Addis_Ababa',
  'Africa/Asmara': 'Africa/Asmara',
  'Africa/Dar_es_Salaam': 'Africa/Dar_es_Salaam',
  'Africa/Djibouti': 'Africa/Djibouti',
  'Africa/Kampala': 'Africa/Kampala',
  'Africa/Mogadishu': 'Africa/Mogadishu',
  'Indian/Antananarivo': 'Indian/Antananarivo',
  'Indian/Comoro': 'Indian/Comoro',
  'Indian/Mayotte': 'Indian/Mayotte',
  'Africa/Monrovia': 'Africa/Monrovia',
  'Africa/Tripoli': 'Africa/Tripoli',
  'Indian/Mauritius': 'Indian/Mauritius',
  'Africa/Casablanca': 'Africa/Casablanca',
  'Africa/El_Aaiun': 'Africa/El_Aaiun',
  'Africa/Maputo': 'Africa/Maputo',
  'Africa/Blantyre': 'Africa/Blantyre',
  'Africa/Bujumbura': 'Africa/Bujumbura',
  'Africa/Gaborone': 'Africa/Gaborone',
  'Africa/Harare': 'Africa/Harare',
  'Africa/Kigali': 'Africa/Kigali',
  'Africa/Lubumbashi': 'Africa/Lubumbashi',
  'Africa/Lusaka': 'Africa/Lusaka',
  'Africa/Windhoek': 'Africa/Windhoek',
  'Africa/Lagos': 'Africa/Lagos',
  'Africa/Bangui': 'Africa/Bangui',
  'Africa/Brazzaville': 'Africa/Brazzaville',
  'Africa/Douala': 'Africa/Douala',
  'Africa/Kinshasa': 'Africa/Kinshasa',
  'Africa/Libreville': 'Africa/Libreville',
  'Africa/Luanda': 'Africa/Luanda',
  'Africa/Malabo': 'Africa/Malabo',
  'Africa/Niamey': 'Africa/Niamey',
  'Africa/Porto-Novo': 'Africa/Porto-Novo',
  'Indian/Reunion': 'Indian/Reunion',
  'Africa/Sao_Tome': 'Africa/Sao_Tome',
  'Indian/Mahe': 'Indian/Mahe',
  'Africa/Johannesburg': 'Africa/Johannesburg',
  'Africa/Maseru': 'Africa/Maseru',
  'Africa/Mbabane': 'Africa/Mbabane',
  'Africa/Khartoum': 'Africa/Khartoum',
  'Africa/Juba': 'Africa/Juba',
  'Africa/Tunis': 'Africa/Tunis',
  'Antarctica/Casey': 'Antarctica/Casey',
  'Antarctica/Davis': 'Antarctica/Davis',
  'Antarctica/Mawson': 'Antarctica/Mawson',
  'Indian/Kerguelen': 'Indian/Kerguelen',
  'Antarctica/DumontDUrville': 'Antarctica/DumontDUrville',
  'Antarctica/Syowa': 'Antarctica/Syowa',
  'Antarctica/Troll': 'Antarctica/Troll',
  'Antarctica/Vostok': 'Antarctica/Vostok',
  'Antarctica/Rothera': 'Antarctica/Rothera',
  'Asia/Kabul': 'Asia/Kabul',
  'Asia/Yerevan': 'Asia/Yerevan',
  'Asia/Baku': 'Asia/Baku',
  'Asia/Dhaka': 'Asia/Dhaka',
  'Asia/Thimphu': 'Asia/Thimphu',
  'Indian/Chagos': 'Indian/Chagos',
  'Asia/Brunei': 'Asia/Brunei',
  'Asia/Yangon': 'Asia/Yangon',
  'Asia/Shanghai': 'Asia/Shanghai',
  'Asia/Urumqi': 'Asia/Urumqi',
  'Asia/Hong_Kong': 'Asia/Hong_Kong',
  'Asia/Taipei': 'Asia/Taipei',
  'Asia/Macau': 'Asia/Macau',
  'Asia/Nicosia': 'Asia/Nicosia',
  'Asia/Famagusta': 'Asia/Famagusta',
  'Europe/Nicosia': 'Europe/Nicosia',
  'Asia/Tbilisi': 'Asia/Tbilisi',
  'Asia/Dili': 'Asia/Dili',
  'Asia/Kolkata': 'Asia/Kolkata',
  'Asia/Jakarta': 'Asia/Jakarta',
  'Asia/Pontianak': 'Asia/Pontianak',
  'Asia/Makassar': 'Asia/Makassar',
  'Asia/Jayapura': 'Asia/Jayapura',
  'Asia/Tehran': 'Asia/Tehran',
  'Asia/Baghdad': 'Asia/Baghdad',
  'Asia/Jerusalem': 'Asia/Jerusalem',
  'Asia/Tokyo': 'Asia/Tokyo',
  'Asia/Amman': 'Asia/Amman',
  'Asia/Almaty': 'Asia/Almaty',
  'Asia/Qyzylorda': 'Asia/Qyzylorda',
  'Asia/Qostanay': 'Asia/Qostanay',
  'Asia/Aqtobe': 'Asia/Aqtobe',
  'Asia/Aqtau': 'Asia/Aqtau',
  'Asia/Atyrau': 'Asia/Atyrau',
  'Asia/Oral': 'Asia/Oral',
  'Asia/Bishkek': 'Asia/Bishkek',
  'Asia/Seoul': 'Asia/Seoul',
  'Asia/Pyongyang': 'Asia/Pyongyang',
  'Asia/Beirut': 'Asia/Beirut',
  'Asia/Kuala_Lumpur': 'Asia/Kuala_Lumpur',
  'Asia/Kuching': 'Asia/Kuching',
  'Indian/Maldives': 'Indian/Maldives',
  'Asia/Hovd': 'Asia/Hovd',
  'Asia/Ulaanbaatar': 'Asia/Ulaanbaatar',
  'Asia/Choibalsan': 'Asia/Choibalsan',
  'Asia/Kathmandu': 'Asia/Kathmandu',
  'Asia/Karachi': 'Asia/Karachi',
  'Asia/Gaza': 'Asia/Gaza',
  'Asia/Hebron': 'Asia/Hebron',
  'Asia/Manila': 'Asia/Manila',
  'Asia/Qatar': 'Asia/Qatar',
  'Asia/Bahrain': 'Asia/Bahrain',
  'Asia/Riyadh': 'Asia/Riyadh',
  'Asia/Aden': 'Asia/Aden',
  'Asia/Kuwait': 'Asia/Kuwait',
  'Asia/Singapore': 'Asia/Singapore',
  'Asia/Colombo': 'Asia/Colombo',
  'Asia/Damascus': 'Asia/Damascus',
  'Asia/Dushanbe': 'Asia/Dushanbe',
  'Asia/Bangkok': 'Asia/Bangkok',
  'Asia/Phnom_Penh': 'Asia/Phnom_Penh',
  'Asia/Vientiane': 'Asia/Vientiane',
  'Asia/Ashgabat': 'Asia/Ashgabat',
  'Asia/Dubai': 'Asia/Dubai',
  'Asia/Muscat': 'Asia/Muscat',
  'Asia/Samarkand': 'Asia/Samarkand',
  'Asia/Tashkent': 'Asia/Tashkent',
  'Asia/Ho_Chi_Minh': 'Asia/Ho_Chi_Minh',
  'Australia/Darwin': 'Australia/Darwin',
  'Australia/Perth': 'Australia/Perth',
  'Australia/Eucla': 'Australia/Eucla',
  'Australia/Brisbane': 'Australia/Brisbane',
  'Australia/Lindeman': 'Australia/Lindeman',
  'Australia/Adelaide': 'Australia/Adelaide',
  'Australia/Hobart': 'Australia/Hobart',
  'Australia/Currie': 'Australia/Currie',
  'Australia/Melbourne': 'Australia/Melbourne',
  'Australia/Sydney': 'Australia/Sydney',
  'Australia/Broken_Hill': 'Australia/Broken_Hill',
  'Australia/Lord_Howe': 'Australia/Lord_Howe',
  'Antarctica/Macquarie': 'Antarctica/Macquarie',
  'Indian/Christmas': 'Indian/Christmas',
  'Indian/Cocos': 'Indian/Cocos',
  'Pacific/Fiji': 'Pacific/Fiji',
  'Pacific/Gambier': 'Pacific/Gambier',
  'Pacific/Marquesas': 'Pacific/Marquesas',
  'Pacific/Tahiti': 'Pacific/Tahiti',
  'Pacific/Guam': 'Pacific/Guam',
  'Pacific/Saipan': 'Pacific/Saipan',
  'Pacific/Tarawa': 'Pacific/Tarawa',
  'Pacific/Enderbury': 'Pacific/Enderbury',
  'Pacific/Kiritimati': 'Pacific/Kiritimati',
  'Pacific/Majuro': 'Pacific/Majuro',
  'Pacific/Kwajalein': 'Pacific/Kwajalein',
  'Pacific/Chuuk': 'Pacific/Chuuk',
  'Pacific/Pohnpei': 'Pacific/Pohnpei',
  'Pacific/Kosrae': 'Pacific/Kosrae',
  'Pacific/Nauru': 'Pacific/Nauru',
  'Pacific/Noumea': 'Pacific/Noumea',
  'Pacific/Auckland': 'Pacific/Auckland',
  'Pacific/Chatham': 'Pacific/Chatham',
  'Antarctica/McMurdo': 'Antarctica/McMurdo',
  'Pacific/Rarotonga': 'Pacific/Rarotonga',
  'Pacific/Niue': 'Pacific/Niue',
  'Pacific/Norfolk': 'Pacific/Norfolk',
  'Pacific/Palau': 'Pacific/Palau',
  'Pacific/Port_Moresby': 'Pacific/Port_Moresby',
  'Pacific/Bougainville': 'Pacific/Bougainville',
  'Pacific/Pitcairn': 'Pacific/Pitcairn',
  'Pacific/Pago_Pago': 'Pacific/Pago_Pago',
  'Pacific/Midway': 'Pacific/Midway',
  'Pacific/Apia': 'Pacific/Apia',
  'Pacific/Guadalcanal': 'Pacific/Guadalcanal',
  'Pacific/Fakaofo': 'Pacific/Fakaofo',
  'Pacific/Tongatapu': 'Pacific/Tongatapu',
  'Pacific/Funafuti': 'Pacific/Funafuti',
  'Pacific/Wake': 'Pacific/Wake',
  'Pacific/Efate': 'Pacific/Efate',
  'Pacific/Wallis': 'Pacific/Wallis',
  'Africa/Asmera': 'Africa/Asmera',
  'Africa/Timbuktu': 'Africa/Timbuktu',
  'America/Argentina/ComodRivadavia': 'America/Argentina/ComodRivadavia',
  'America/Atka': 'America/Atka',
  'America/Buenos_Aires': 'America/Buenos_Aires',
  'America/Catamarca': 'America/Catamarca',
  'America/Coral_Harbour': 'America/Coral_Harbour',
  'America/Cordoba': 'America/Cordoba',
  'America/Ensenada': 'America/Ensenada',
  'America/Fort_Wayne': 'America/Fort_Wayne',
  'America/Indianapolis': 'America/Indianapolis',
  'America/Jujuy': 'America/Jujuy',
  'America/Knox_IN': 'America/Knox_IN',
  'America/Louisville': 'America/Louisville',
  'America/Mendoza': 'America/Mendoza',
  'America/Montreal': 'America/Montreal',
  'America/Porto_Acre': 'America/Porto_Acre',
  'America/Rosario': 'America/Rosario',
  'America/Santa_Isabel': 'America/Santa_Isabel',
  'America/Shiprock': 'America/Shiprock',
  'America/Virgin': 'America/Virgin',
  'Antarctica/South_Pole': 'Antarctica/South_Pole',
  'Asia/Ashkhabad': 'Asia/Ashkhabad',
  'Asia/Calcutta': 'Asia/Calcutta',
  'Asia/Chongqing': 'Asia/Chongqing',
  'Asia/Chungking': 'Asia/Chungking',
  'Asia/Dacca': 'Asia/Dacca',
  'Asia/Harbin': 'Asia/Harbin',
  'Asia/Kashgar': 'Asia/Kashgar',
  'Asia/Katmandu': 'Asia/Katmandu',
  'Asia/Macao': 'Asia/Macao',
  'Asia/Rangoon': 'Asia/Rangoon',
  'Asia/Saigon': 'Asia/Saigon',
  'Asia/Tel_Aviv': 'Asia/Tel_Aviv',
  'Asia/Thimbu': 'Asia/Thimbu',
  'Asia/Ujung_Pandang': 'Asia/Ujung_Pandang',
  'Asia/Ulan_Bator': 'Asia/Ulan_Bator',
  'Atlantic/Faeroe': 'Atlantic/Faeroe',
  'Atlantic/Jan_Mayen': 'Atlantic/Jan_Mayen',
  'Australia/ACT': 'Australia/ACT',
  'Australia/Canberra': 'Australia/Canberra',
  'Australia/LHI': 'Australia/LHI',
  'Australia/NSW': 'Australia/NSW',
  'Australia/North': 'Australia/North',
  'Australia/Queensland': 'Australia/Queensland',
  'Australia/South': 'Australia/South',
  'Australia/Tasmania': 'Australia/Tasmania',
  'Australia/Victoria': 'Australia/Victoria',
  'Australia/West': 'Australia/West',
  'Australia/Yancowinna': 'Australia/Yancowinna',
  'Brazil/Acre': 'Brazil/Acre',
  'Brazil/DeNoronha': 'Brazil/DeNoronha',
  'Brazil/East': 'Brazil/East',
  'Brazil/West': 'Brazil/West',
  'Canada/Atlantic': 'Canada/Atlantic',
  'Canada/Central': 'Canada/Central',
  'Canada/Eastern': 'Canada/Eastern',
  'Canada/Mountain': 'Canada/Mountain',
  'Canada/Newfoundland': 'Canada/Newfoundland',
  'Canada/Pacific': 'Canada/Pacific',
  'Canada/Saskatchewan': 'Canada/Saskatchewan',
  'Canada/Yukon': 'Canada/Yukon',
  'Chile/Continental': 'Chile/Continental',
  'Chile/EasterIsland': 'Chile/EasterIsland',
  Cuba: 'Cuba',
  Egypt: 'Egypt',
  Eire: 'Eire',
  'Etc/UCT': 'Etc/UCT',
  'Europe/Belfast': 'Europe/Belfast',
  'Europe/Tiraspol': 'Europe/Tiraspol',
  GB: 'GB',
  'GB-Eire': 'GB-Eire',
  'GMT+0': 'GMT+0',
  'GMT-0': 'GMT-0',
  GMT0: 'GMT0',
  Greenwich: 'Greenwich',
  Hongkong: 'Hongkong',
  Iceland: 'Iceland',
  Iran: 'Iran',
  Israel: 'Israel',
  Jamaica: 'Jamaica',
  Japan: 'Japan',
  Kwajalein: 'Kwajalein',
  Libya: 'Libya',
  'Mexico/BajaNorte': 'Mexico/BajaNorte',
  'Mexico/BajaSur': 'Mexico/BajaSur',
  'Mexico/General': 'Mexico/General',
  NZ: 'NZ',
  'NZ-CHAT': 'NZ-CHAT',
  Navajo: 'Navajo',
  PRC: 'PRC',
  'Pacific/Johnston': 'Pacific/Johnston',
  'Pacific/Ponape': 'Pacific/Ponape',
  'Pacific/Samoa': 'Pacific/Samoa',
  'Pacific/Truk': 'Pacific/Truk',
  'Pacific/Yap': 'Pacific/Yap',
  Poland: 'Poland',
  Portugal: 'Portugal',
  ROC: 'ROC',
  ROK: 'ROK',
  Singapore: 'Singapore',
  Turkey: 'Turkey',
  UCT: 'UCT',
  'US/Alaska': 'US/Alaska',
  'US/Aleutian': 'US/Aleutian',
  'US/Arizona': 'US/Arizona',
  'US/Central': 'US/Central',
  'US/East-Indiana': 'US/East-Indiana',
  'US/Eastern': 'US/Eastern',
  'US/Hawaii': 'US/Hawaii',
  'US/Indiana-Starke': 'US/Indiana-Starke',
  'US/Michigan': 'US/Michigan',
  'US/Mountain': 'US/Mountain',
  'US/Pacific': 'US/Pacific',
  'US/Samoa': 'US/Samoa',
  UTC: 'UTC',
  Universal: 'Universal',
  'W-SU': 'W-SU',
  Zulu: 'Zulu',
  'Etc/GMT': 'Etc/GMT',
  'Etc/UTC': 'Etc/UTC',
  GMT: 'GMT',
  'Etc/Universal': 'Etc/Universal',
  'Etc/Zulu': 'Etc/Zulu',
  'Etc/Greenwich': 'Etc/Greenwich',
  'Etc/GMT-0': 'Etc/GMT-0',
  'Etc/GMT+0': 'Etc/GMT+0',
  'Etc/GMT0': 'Etc/GMT0',
  'Etc/GMT-14': 'Etc/GMT-14',
  'Etc/GMT-13': 'Etc/GMT-13',
  'Etc/GMT-12': 'Etc/GMT-12',
  'Etc/GMT-11': 'Etc/GMT-11',
  'Etc/GMT-10': 'Etc/GMT-10',
  'Etc/GMT-9': 'Etc/GMT-9',
  'Etc/GMT-8': 'Etc/GMT-8',
  'Etc/GMT-7': 'Etc/GMT-7',
  'Etc/GMT-6': 'Etc/GMT-6',
  'Etc/GMT-5': 'Etc/GMT-5',
  'Etc/GMT-4': 'Etc/GMT-4',
  'Etc/GMT-3': 'Etc/GMT-3',
  'Etc/GMT-2': 'Etc/GMT-2',
  'Etc/GMT-1': 'Etc/GMT-1',
  'Etc/GMT+1': 'Etc/GMT+1',
  'Etc/GMT+2': 'Etc/GMT+2',
  'Etc/GMT+3': 'Etc/GMT+3',
  'Etc/GMT+4': 'Etc/GMT+4',
  'Etc/GMT+5': 'Etc/GMT+5',
  'Etc/GMT+6': 'Etc/GMT+6',
  'Etc/GMT+7': 'Etc/GMT+7',
  'Etc/GMT+8': 'Etc/GMT+8',
  'Etc/GMT+9': 'Etc/GMT+9',
  'Etc/GMT+10': 'Etc/GMT+10',
  'Etc/GMT+11': 'Etc/GMT+11',
  'Etc/GMT+12': 'Etc/GMT+12',
  'Europe/London': 'Europe/London',
  'Europe/Jersey': 'Europe/Jersey',
  'Europe/Guernsey': 'Europe/Guernsey',
  'Europe/Isle_of_Man': 'Europe/Isle_of_Man',
  'Europe/Dublin': 'Europe/Dublin',
  WET: 'WET',
  CET: 'CET',
  MET: 'MET',
  EET: 'EET',
  'Europe/Tirane': 'Europe/Tirane',
  'Europe/Andorra': 'Europe/Andorra',
  'Europe/Vienna': 'Europe/Vienna',
  'Europe/Minsk': 'Europe/Minsk',
  'Europe/Brussels': 'Europe/Brussels',
  'Europe/Sofia': 'Europe/Sofia',
  'Europe/Prague': 'Europe/Prague',
  'Europe/Copenhagen': 'Europe/Copenhagen',
  'Atlantic/Faroe': 'Atlantic/Faroe',
  'America/Danmarkshavn': 'America/Danmarkshavn',
  'America/Scoresbysund': 'America/Scoresbysund',
  'America/Godthab': 'America/Godthab',
  'America/Thule': 'America/Thule',
  'Europe/Tallinn': 'Europe/Tallinn',
  'Europe/Helsinki': 'Europe/Helsinki',
  'Europe/Mariehamn': 'Europe/Mariehamn',
  'Europe/Paris': 'Europe/Paris',
  'Europe/Berlin': 'Europe/Berlin',
  'Europe/Busingen': 'Europe/Busingen',
  'Europe/Gibraltar': 'Europe/Gibraltar',
  'Europe/Athens': 'Europe/Athens',
  'Europe/Budapest': 'Europe/Budapest',
  'Atlantic/Reykjavik': 'Atlantic/Reykjavik',
  'Europe/Rome': 'Europe/Rome',
  'Europe/Vatican': 'Europe/Vatican',
  'Europe/San_Marino': 'Europe/San_Marino',
  'Europe/Riga': 'Europe/Riga',
  'Europe/Vaduz': 'Europe/Vaduz',
  'Europe/Vilnius': 'Europe/Vilnius',
  'Europe/Luxembourg': 'Europe/Luxembourg',
  'Europe/Malta': 'Europe/Malta',
  'Europe/Chisinau': 'Europe/Chisinau',
  'Europe/Monaco': 'Europe/Monaco',
  'Europe/Amsterdam': 'Europe/Amsterdam',
  'Europe/Oslo': 'Europe/Oslo',
  'Arctic/Longyearbyen': 'Arctic/Longyearbyen',
  'Europe/Warsaw': 'Europe/Warsaw',
  'Europe/Lisbon': 'Europe/Lisbon',
  'Atlantic/Azores': 'Atlantic/Azores',
  'Atlantic/Madeira': 'Atlantic/Madeira',
  'Europe/Bucharest': 'Europe/Bucharest',
  'Europe/Kaliningrad': 'Europe/Kaliningrad',
  'Europe/Moscow': 'Europe/Moscow',
  'Europe/Simferopol': 'Europe/Simferopol',
  'Europe/Astrakhan': 'Europe/Astrakhan',
  'Europe/Volgograd': 'Europe/Volgograd',
  'Europe/Saratov': 'Europe/Saratov',
  'Europe/Kirov': 'Europe/Kirov',
  'Europe/Samara': 'Europe/Samara',
  'Europe/Ulyanovsk': 'Europe/Ulyanovsk',
  'Asia/Yekaterinburg': 'Asia/Yekaterinburg',
  'Asia/Omsk': 'Asia/Omsk',
  'Asia/Barnaul': 'Asia/Barnaul',
  'Asia/Novosibirsk': 'Asia/Novosibirsk',
  'Asia/Tomsk': 'Asia/Tomsk',
  'Asia/Novokuznetsk': 'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk': 'Asia/Krasnoyarsk',
  'Asia/Irkutsk': 'Asia/Irkutsk',
  'Asia/Chita': 'Asia/Chita',
  'Asia/Yakutsk': 'Asia/Yakutsk',
  'Asia/Vladivostok': 'Asia/Vladivostok',
  'Asia/Khandyga': 'Asia/Khandyga',
  'Asia/Sakhalin': 'Asia/Sakhalin',
  'Asia/Magadan': 'Asia/Magadan',
  'Asia/Srednekolymsk': 'Asia/Srednekolymsk',
  'Asia/Ust-Nera': 'Asia/Ust-Nera',
  'Asia/Kamchatka': 'Asia/Kamchatka',
  'Asia/Anadyr': 'Asia/Anadyr',
  'Europe/Belgrade': 'Europe/Belgrade',
  'Europe/Ljubljana': 'Europe/Ljubljana',
  'Europe/Podgorica': 'Europe/Podgorica',
  'Europe/Sarajevo': 'Europe/Sarajevo',
  'Europe/Skopje': 'Europe/Skopje',
  'Europe/Zagreb': 'Europe/Zagreb',
  'Europe/Bratislava': 'Europe/Bratislava',
  'Europe/Madrid': 'Europe/Madrid',
  'Africa/Ceuta': 'Africa/Ceuta',
  'Atlantic/Canary': 'Atlantic/Canary',
  'Europe/Stockholm': 'Europe/Stockholm',
  'Europe/Zurich': 'Europe/Zurich',
  'Europe/Istanbul': 'Europe/Istanbul',
  'Asia/Istanbul': 'Asia/Istanbul',
  'Europe/Kiev': 'Europe/Kiev',
  'Europe/Uzhgorod': 'Europe/Uzhgorod',
  'Europe/Zaporozhye': 'Europe/Zaporozhye',
  Factory: 'Factory',
  EST: 'EST',
  MST: 'MST',
  HST: 'HST',
  EST5EDT: 'EST5EDT',
  CST6CDT: 'CST6CDT',
  MST7MDT: 'MST7MDT',
  PST8PDT: 'PST8PDT',
  'America/New_York': 'America/New_York',
  'America/Chicago': 'America/Chicago',
  'America/North_Dakota/Center': 'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem': 'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah': 'America/North_Dakota/Beulah',
  'America/Denver': 'America/Denver',
  'America/Los_Angeles': 'America/Los_Angeles',
  'America/Juneau': 'America/Juneau',
  'America/Sitka': 'America/Sitka',
  'America/Metlakatla': 'America/Metlakatla',
  'America/Yakutat': 'America/Yakutat',
  'America/Anchorage': 'America/Anchorage',
  'America/Nome': 'America/Nome',
  'America/Adak': 'America/Adak',
  'Pacific/Honolulu': 'Pacific/Honolulu',
  'America/Phoenix': 'America/Phoenix',
  'America/Boise': 'America/Boise',
  'America/Indiana/Indianapolis': 'America/Indiana/Indianapolis',
  'America/Indiana/Marengo': 'America/Indiana/Marengo',
  'America/Indiana/Vincennes': 'America/Indiana/Vincennes',
  'America/Indiana/Tell_City': 'America/Indiana/Tell_City',
  'America/Indiana/Petersburg': 'America/Indiana/Petersburg',
  'America/Indiana/Knox': 'America/Indiana/Knox',
  'America/Indiana/Winamac': 'America/Indiana/Winamac',
  'America/Indiana/Vevay': 'America/Indiana/Vevay',
  'America/Kentucky/Louisville': 'America/Kentucky/Louisville',
  'America/Kentucky/Monticello': 'America/Kentucky/Monticello',
  'America/Detroit': 'America/Detroit',
  'America/Menominee': 'America/Menominee',
  'America/St_Johns': 'America/St_Johns',
  'America/Goose_Bay': 'America/Goose_Bay',
  'America/Halifax': 'America/Halifax',
  'America/Glace_Bay': 'America/Glace_Bay',
  'America/Moncton': 'America/Moncton',
  'America/Blanc-Sablon': 'America/Blanc-Sablon',
  'America/Toronto': 'America/Toronto',
  'America/Thunder_Bay': 'America/Thunder_Bay',
  'America/Nipigon': 'America/Nipigon',
  'America/Rainy_River': 'America/Rainy_River',
  'America/Atikokan': 'America/Atikokan',
  'America/Winnipeg': 'America/Winnipeg',
  'America/Regina': 'America/Regina',
  'America/Swift_Current': 'America/Swift_Current',
  'America/Edmonton': 'America/Edmonton',
  'America/Vancouver': 'America/Vancouver',
  'America/Dawson_Creek': 'America/Dawson_Creek',
  'America/Fort_Nelson': 'America/Fort_Nelson',
  'America/Creston': 'America/Creston',
  'America/Pangnirtung': 'America/Pangnirtung',
  'America/Iqaluit': 'America/Iqaluit',
  'America/Resolute': 'America/Resolute',
  'America/Rankin_Inlet': 'America/Rankin_Inlet',
  'America/Cambridge_Bay': 'America/Cambridge_Bay',
  'America/Yellowknife': 'America/Yellowknife',
  'America/Inuvik': 'America/Inuvik',
  'America/Whitehorse': 'America/Whitehorse',
  'America/Dawson': 'America/Dawson',
  'America/Cancun': 'America/Cancun',
  'America/Merida': 'America/Merida',
  'America/Matamoros': 'America/Matamoros',
  'America/Monterrey': 'America/Monterrey',
  'America/Mexico_City': 'America/Mexico_City',
  'America/Ojinaga': 'America/Ojinaga',
  'America/Chihuahua': 'America/Chihuahua',
  'America/Hermosillo': 'America/Hermosillo',
  'America/Mazatlan': 'America/Mazatlan',
  'America/Bahia_Banderas': 'America/Bahia_Banderas',
  'America/Tijuana': 'America/Tijuana',
  'America/Nassau': 'America/Nassau',
  'America/Barbados': 'America/Barbados',
  'America/Belize': 'America/Belize',
  'Atlantic/Bermuda': 'Atlantic/Bermuda',
  'America/Costa_Rica': 'America/Costa_Rica',
  'America/Havana': 'America/Havana',
  'America/Santo_Domingo': 'America/Santo_Domingo',
  'America/El_Salvador': 'America/El_Salvador',
  'America/Guatemala': 'America/Guatemala',
  'America/Port-au-Prince': 'America/Port-au-Prince',
  'America/Tegucigalpa': 'America/Tegucigalpa',
  'America/Jamaica': 'America/Jamaica',
  'America/Martinique': 'America/Martinique',
  'America/Managua': 'America/Managua',
  'America/Panama': 'America/Panama',
  'America/Cayman': 'America/Cayman',
  'America/Puerto_Rico': 'America/Puerto_Rico',
  'America/Miquelon': 'America/Miquelon',
  'America/Grand_Turk': 'America/Grand_Turk',
  'US/Pacific-New': 'US/Pacific-New',
  'America/Argentina/Buenos_Aires': 'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba': 'America/Argentina/Cordoba',
  'America/Argentina/Salta': 'America/Argentina/Salta',
  'America/Argentina/Tucuman': 'America/Argentina/Tucuman',
  'America/Argentina/La_Rioja': 'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan': 'America/Argentina/San_Juan',
  'America/Argentina/Jujuy': 'America/Argentina/Jujuy',
  'America/Argentina/Catamarca': 'America/Argentina/Catamarca',
  'America/Argentina/Mendoza': 'America/Argentina/Mendoza',
  'America/Argentina/San_Luis': 'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos': 'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia': 'America/Argentina/Ushuaia',
  'America/Aruba': 'America/Aruba',
  'America/La_Paz': 'America/La_Paz',
  'America/Noronha': 'America/Noronha',
  'America/Belem': 'America/Belem',
  'America/Santarem': 'America/Santarem',
  'America/Fortaleza': 'America/Fortaleza',
  'America/Recife': 'America/Recife',
  'America/Araguaina': 'America/Araguaina',
  'America/Maceio': 'America/Maceio',
  'America/Bahia': 'America/Bahia',
  'America/Sao_Paulo': 'America/Sao_Paulo',
  'America/Campo_Grande': 'America/Campo_Grande',
  'America/Cuiaba': 'America/Cuiaba',
  'America/Porto_Velho': 'America/Porto_Velho',
  'America/Boa_Vista': 'America/Boa_Vista',
  'America/Manaus': 'America/Manaus',
  'America/Eirunepe': 'America/Eirunepe',
  'America/Rio_Branco': 'America/Rio_Branco',
  'America/Santiago': 'America/Santiago',
  'America/Punta_Arenas': 'America/Punta_Arenas',
  'Pacific/Easter': 'Pacific/Easter',
  'Antarctica/Palmer': 'Antarctica/Palmer',
  'America/Bogota': 'America/Bogota',
  'America/Curacao': 'America/Curacao',
  'America/Lower_Princes': 'America/Lower_Princes',
  'America/Kralendijk': 'America/Kralendijk',
  'America/Guayaquil': 'America/Guayaquil',
  'Pacific/Galapagos': 'Pacific/Galapagos',
  'Atlantic/Stanley': 'Atlantic/Stanley',
  'America/Cayenne': 'America/Cayenne',
  'America/Guyana': 'America/Guyana',
  'America/Asuncion': 'America/Asuncion',
  'America/Lima': 'America/Lima',
  'Atlantic/South_Georgia': 'Atlantic/South_Georgia',
  'America/Paramaribo': 'America/Paramaribo',
  'America/Port_of_Spain': 'America/Port_of_Spain',
  'America/Anguilla': 'America/Anguilla',
  'America/Antigua': 'America/Antigua',
  'America/Dominica': 'America/Dominica',
  'America/Grenada': 'America/Grenada',
  'America/Guadeloupe': 'America/Guadeloupe',
  'America/Marigot': 'America/Marigot',
  'America/Montserrat': 'America/Montserrat',
  'America/St_Barthelemy': 'America/St_Barthelemy',
  'America/St_Kitts': 'America/St_Kitts',
  'America/St_Lucia': 'America/St_Lucia',
  'America/St_Thomas': 'America/St_Thomas',
  'America/St_Vincent': 'America/St_Vincent',
  'America/Tortola': 'America/Tortola',
  'America/Montevideo': 'America/Montevideo',
  'America/Caracas': 'America/Caracas',
} as const

export type SiteBillingType =
  (typeof SiteBillingType)[keyof typeof SiteBillingType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SiteBillingType = {
  Standard: 'Standard',
  Discount: 'Discount',
  NonBilled: 'NonBilled',
} as const

export interface SiteSnapshot {
  conductReport?: ConductReport
  missed: GroupedCompletions
  recent: GroupedCompletions
  subscribed?: User[]
  upcoming: GroupedCompletions
}

export interface UserStats {
  adjustedComposite: number
  composite: number
  negativeEvals: number
  normalizedComposite: number
  positiveEvals: number
  tasksCompleted: number
  tasksDueWhileOnSite: number
  tasksMissed: number
  tasksSkipped: number
}

export interface UserConduct {
  sitePositionId: Uuid
  unweightedStats: UserStats
  userId: Uuid
  weightedStats: UserStats
}

export interface ConductReport {
  averageConduct: UserConduct
  taskCompletionPct: number
  userConduct: UserConduct[]
}

export interface SitePosition {
  canWallboardLogin?: boolean
  positionId: DoubleUUID
  siteId: Uuid
}

export type PrivLevel = (typeof PrivLevel)[keyof typeof PrivLevel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrivLevel = {
  read: 'read',
  readWrite: 'readWrite',
} as const

export interface SiteAdminPriv {
  privLevel: PrivLevel
  siteId: Uuid
}

/**
 * @pattern [a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]{0,61}\.[a-zA-Z0-9-]{0,61}
 */
export type Email = string

export interface RequestPasswordReset {
  email: Email
}

export interface Registration {
  email: Email
  name: string
  organizationName: string
  phone: string
}

export interface UserLogin {
  email: Email
  password: string
}

export interface Site {
  address: string
  billingOrganizationId: Uuid
  billingStartDate?: string
  billingType: SiteBillingType
  cts?: string
  emailToCCOnContactRequests?: Email[]
  geolocation: SiteGeolocation
  id: Uuid
  mts?: string
  name: string
  organizationId: Uuid
  orgTemplateUsages?: AppliedOrgTemplate[]
  shutdown?: Shutdown
  siteTagIds?: string[]
  storeHours?: StoreHours
  templateStatus?: SiteTemplateStatus
  timezone: Timezone
  wallboardSettings?: WallboardSettings
  zipCode: string
}

export interface SelfResponse {
  active: boolean
  cts?: string
  email: Email
  id: Uuid
  isAdmin: boolean
  mts?: string
  name: string
  notificationSubscribedSite?: Uuid
  organizationId: Uuid
  phone: string
  siteAdminPrivs?: SiteAdminPriv[]
  siteIdsRecReportsFrom?: Uuid[]
  sitePositions: SitePosition[]
  sites?: Site[]
}

export interface User {
  active: boolean
  cts?: string
  email: Email
  id: Uuid
  isAdmin: boolean
  mts?: string
  name: string
  organizationId: Uuid
  phone: string
  siteAdminPrivs?: SiteAdminPriv[]
  siteIdsRecReportsFrom?: Uuid[]
  sitePositions: SitePosition[]
}

export type GroupedCompletionsTaskGroupsById = { [key: string]: TaskGroup }

export interface GroupedCompletions {
  completionsByTaskGroupId: GroupedCompletionsCompletionsByTaskGroupId
  taskGroupsById: GroupedCompletionsTaskGroupsById
  totalCompletions: number
}

export type GroupedCompletionsCompletionsByTaskGroupIdByGroup = {
  [key: string]: Completion[]
}

export interface MultiChoiceOption {
  display: string
  key: string
}

export interface MultiChoiceQuestion {
  answer?: string
  isAggregation?: boolean
  key: string
  options: MultiChoiceOption[]
  question: string
}

export interface Completion {
  advancedViewing?: boolean
  canSkip: boolean
  commentIds?: Uuid[]
  completedAtTime?: string
  completedByUserId?: Uuid
  cts?: string
  description: string
  dueDate: string
  escalationIds?: Uuid[]
  evaluation?: Evaluation
  id: DoubleUUID
  isAnyTime?: boolean
  isComplete: boolean
  isSkipped: boolean
  mts?: string
  multiChoice?: MultiChoiceQuestion[]
  onSiteAtDueDateUserIds?: Uuid[]
  organizationId: Uuid
  positionIds: Uuid[]
  positions?: Position[]
  siteId: Uuid
  status: CompletionStatus
  taskGroupIds?: Uuid[]
  taskId: Uuid
  title: string
}

export type GroupedCompletionsCompletionsByTaskGroupId = {
  byGroup: GroupedCompletionsCompletionsByTaskGroupIdByGroup
  noGroup: Completion[]
}

export type EvaluationSentiment =
  (typeof EvaluationSentiment)[keyof typeof EvaluationSentiment]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EvaluationSentiment = {
  positive: 'positive',
  negative: 'negative',
} as const

export type CompletionStatus =
  (typeof CompletionStatus)[keyof typeof CompletionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompletionStatus = {
  upcoming: 'upcoming',
  missed: 'missed',
  skipped: 'skipped',
  complete: 'complete',
  processing: 'processing',
  failed: 'failed',
} as const

export interface OrgTemplateData {
  baseId?: DoubleUUID
  locked?: boolean
  modifiedByConsumer?: boolean
  orgTemplateId?: Uuid
}

export interface TaskgroupUiData {
  color?: string
}

/**
 * Stringified UUIDv4.
 See [RFC 4112](https://tools.ietf.org/html/rfc4122)
 * @pattern ^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$
 */
export type Uuid = string

export interface Evaluation {
  evaluatingUserId: Uuid
  sentiment: EvaluationSentiment
  ts: string
}

/**
 * Stringified UUIDv4.
 See [RFC 4112](https://tools.ietf.org/html/rfc4122)
 * @pattern ^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}_[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$
 */
export type DoubleUUID = string

export interface Position {
  cts?: string
  id: DoubleUUID
  mts?: string
  organizationId: Uuid
  orgTemplateData?: OrgTemplateData
  positionIds: DoubleUUID[]
  siteId: Uuid
  title: string
  unScored?: boolean
}

export interface TaskGroup {
  cts?: string
  details: string
  id: DoubleUUID
  mts?: string
  name: string
  organizationId: Uuid
  orgTemplateData?: OrgTemplateData
  siteId: Uuid
  uiData?: TaskgroupUiData
}

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getGroupedCompletions = (
  params: GetGroupedCompletionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GroupedCompletions>(
    {
      url: `/getGroupedCompletionsForUser`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const siteSnapshotForUser = (
  params: SiteSnapshotForUserParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SiteSnapshot>(
    {
      url: `/siteSnapshotForUser`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const siteSnapshotForAdmin = (
  params: SiteSnapshotForAdminParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SiteSnapshot>(
    {
      url: `/siteSnapshotForAdmin`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getSelf = (
  params?: GetSelfParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SelfResponse>(
    {
      url: `/getSelf`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const loginUserF = (
  userLogin: BodyType<UserLogin>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LoginSuccess>(
    {
      url: `/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userLogin,
    },
    options,
  )
}

export const logoutUser = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/logout`,
      method: 'POST',
    },
    options,
  )
}

export const refreshUserToken = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LoginSuccess>(
    {
      url: `/refreshToken`,
      method: 'POST',
    },
    options,
  )
}

export const addPushToken = (
  pushTokenData: BodyType<PushTokenData>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/addPushToken`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: pushTokenData,
    },
    options,
  )
}

export const register = (
  registration: BodyType<Registration>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/register`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: registration,
    },
    options,
  )
}

export const requestReset = (
  requestPasswordReset: BodyType<RequestPasswordReset>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/resetPassword`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: requestPasswordReset,
    },
    options,
  )
}

export const confirmReset = (
  confirmPasswordReset: BodyType<ConfirmPasswordReset>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/confirmPasswordReset`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: confirmPasswordReset,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const wallboardGetLoginToken = (
  params: WallboardGetLoginTokenParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<string>(
    {
      url: `/wallboardGetLoginToken`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const wallboardLoginUsers = (
  params: WallboardLoginUsersParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<WallboardLoginUsers200Item[]>(
    {
      url: `/wallboardLoginUsers`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const loginViaWallboard = (
  loginViaWallboardBody: BodyType<LoginViaWallboardBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LoginSuccess>(
    {
      url: `/loginViaWallboard`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: loginViaWallboardBody,
    },
    options,
  )
}

export const getActiveBilling = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<OrgBillingDetails[]>(
    {
      url: `/getActiveBilling`,
      method: 'GET',
    },
    options,
  )
}

export const setPaymentMethod = (
  setPaymentMethodBody: BodyType<SetPaymentMethodBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CardDetails>(
    {
      url: `/setPaymentMethod`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: setPaymentMethodBody,
    },
    options,
  )
}

export const modifySubscriptionStatus = (
  modifySubscriptionStatusBody: BodyType<ModifySubscriptionStatusBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/modifySubscriptionStatus`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: modifySubscriptionStatusBody,
    },
    options,
  )
}

export const getCurrentPaymentMethod = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetCurrentPaymentMethod200>(
    {
      url: `/getCurrentPaymentMethod`,
      method: 'GET',
    },
    options,
  )
}

export const getInvoices = (
  params: GetInvoicesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Invoice[]>(
    {
      url: `/getInvoices`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const checkForBotToken = (
  params: CheckForBotTokenParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CheckForBotToken200>(
    {
      url: `/checkForBotToken`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const authorizeSingeSiteReadBot = (
  createBotInput: BodyType<CreateBotInput>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/authorizeSingeSiteReadBot`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createBotInput,
    },
    options,
  )
}

export const getBrands = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Brand[]>(
    {
      url: `/getBrands`,
      method: 'GET',
    },
    options,
  )
}

export const loadCommentsByIds = (
  params: LoadCommentsByIdsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Comment[]>(
    {
      url: `/loadCommentsByIds`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const createComment = (
  createCommentInput: BodyType<CreateCommentInput>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Comment>(
    {
      url: `/createComment`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createCommentInput,
    },
    options,
  )
}

export const getUserCompletions = (
  params: GetUserCompletionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Completion[]>(
    {
      url: `/userToDo`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getFileUploadUrl = (
  completionFileUploadRequest: BodyType<CompletionFileUploadRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CompletionFileUploadRespnse>(
    {
      url: `/generateFileUploadUrl`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: completionFileUploadRequest,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const getAllCompletions = (
  params: GetAllCompletionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CompletionList>(
    {
      url: `/loadTaskCompletions`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const markComplete = (
  markTaskComplete: BodyType<MarkTaskComplete>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/markComplete`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: markTaskComplete,
    },
    options,
  )
}

export const createAnytimeCompletion = (
  createAnytimeCompletionBody: BodyType<CreateAnytimeCompletionBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Completion>(
    {
      url: `/createAnytimeCompletion`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createAnytimeCompletionBody,
    },
    options,
  )
}

export const markProcessing = (
  markTaskComplete: BodyType<MarkTaskComplete>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/markProcessing`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: markTaskComplete,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const completionEval = (
  evaluateCompletion: BodyType<EvaluateCompletion>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/completionEval`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: evaluateCompletion,
    },
    options,
  )
}

export const allContacts = (
  params?: AllContactsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ContactList>(
    {
      url: `/allContacts`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getContactsBySite = (
  params: GetContactsBySiteParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ContactList>(
    {
      url: `/contactsBySite`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getContact = (
  params: GetContactParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Contact>(
    {
      url: `/contact`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const createContact = (
  createContact: BodyType<CreateContact>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Contact>(
    {
      url: `/contact`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createContact,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const modifyContact = (
  modifyContact: BodyType<ModifyContact>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Contact>(
    {
      url: `/contact`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: modifyContact,
    },
    options,
  )
}

/**
 * @summary Admin Only
 */
export const deleteContact = (
  params: DeleteContactParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/contact`,
      method: 'DELETE',
      params,
    },
    options,
  )
}

export const getEmailPrefs = (
  params: GetEmailPrefsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<EmailPreferences>(
    {
      url: `/getEmailPrefs`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const modEmailPrefs = (
  modEmailPreferences: BodyType<ModEmailPreferences>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<EmailPreferences>(
    {
      url: `/modEmailPrefs`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: modEmailPreferences,
    },
    options,
  )
}

export const getEscalations = (
  params: GetEscalationsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<EscalationList>(
    {
      url: `/escalations`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getEscalation = (
  params: GetEscalationParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Escalation>(
    {
      url: `/escalation`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin  & site admin only
 */
export const createEscalation = (
  createEscalation: BodyType<CreateEscalation>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Escalation>(
    {
      url: `/escalation`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createEscalation,
    },
    options,
  )
}

/**
 * @summary Admin & Site Admin Only
 */
export const modifyEscalation = (
  modifyEscalation: BodyType<ModifyEscalation>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Escalation>(
    {
      url: `/escalation`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: modifyEscalation,
    },
    options,
  )
}

/**
 * @summary Admin & site admin only
 */
export const deleteEscalation = (
  params: DeleteEscalationParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/escalation`,
      method: 'DELETE',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const getEscOccurrences = (
  params: GetEscOccurrencesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<EscOccurrenceList>(
    {
      url: `/escOccurrencesByTimeRange`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const resolveEscOccurrence = (
  resolveEscOccurrence: BodyType<ResolveEscOccurrence>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/resolveEscOccurrence`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: resolveEscOccurrence,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const emailEscOccurrence = (
  emailEscOccurrence: BodyType<EmailEscOccurrence>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/emailEscOccurrenceToExternal`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: emailEscOccurrence,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const sendTestEmailToExternal = (
  emailTestMessage: BodyType<EmailTestMessage>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/sendTestEmailToExternal`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: emailTestMessage,
    },
    options,
  )
}

export const handleGeofence = (
  geofenceEventBody: BodyType<GeofenceEventBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/handleGeofence`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: geofenceEventBody,
    },
    options,
  )
}

export const markSubscribed = (
  subscribeBody: BodyType<SubscribeBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/subscribeSite`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: subscribeBody,
    },
    options,
  )
}

export const markUnsubscribed = (
  unsubscribeBody: BodyType<UnsubscribeBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/unsubscribeSite`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: unsubscribeBody,
    },
    options,
  )
}

/**
 * @summary Dev Only - if no token is included, will try to find your token using from authorization header (jwt)
 */
export const pushTokenTest = (
  pushTokenTestBody: BodyType<PushTokenTestBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/pushTokenTest`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: pushTokenTestBody,
    },
    options,
  )
}

export const getOrganization = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Organization>(
    {
      url: `/organization`,
      method: 'GET',
    },
    options,
  )
}

/**
 * @summary Admin Only
 */
export const modifyOrganization = (
  organizationEdit: BodyType<OrganizationEdit>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Organization>(
    {
      url: `/organization`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: organizationEdit,
    },
    options,
  )
}

/**
 * @summary Admin Only
 */
export const allOrgTemplates = (
  params: AllOrgTemplatesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<OrgTemplateDisplayList>(
    {
      url: `/allOrgTemplates`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin and Site Admin Only
 */
export const useOrgTemplate = (
  useOrgTemplate: BodyType<UseOrgTemplate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/useOrgTemplate`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: useOrgTemplate,
    },
    options,
  )
}

/**
 * @summary Admin and Site Admin Only
 */
export const removeOrgTemplate = (
  useOrgTemplate: BodyType<UseOrgTemplate>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/removeOrgTemplate`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: useOrgTemplate,
    },
    options,
  )
}

export const getPositions = (
  params: GetPositionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<PositionList>(
    {
      url: `/positions`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getPosition = (
  params: GetPositionParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Position>(
    {
      url: `/position`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin or Site Admin Only
 */
export const createPosition = (
  createPosition: BodyType<CreatePosition>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Position>(
    {
      url: `/position`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createPosition,
    },
    options,
  )
}

/**
 * @summary Admin or Site Admin Only
 */
export const modifyPosition = (
  modifyPosition: BodyType<ModifyPosition>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Position>(
    {
      url: `/position`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: modifyPosition,
    },
    options,
  )
}

/**
 * @summary Admin or Site Admin Only
 */
export const deletePosition = (
  params: DeletePositionParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/position`,
      method: 'DELETE',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const conductReport = (
  params: ConductReportParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ConductReport>(
    {
      url: `/conductReport`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const rollingStatsReport = (
  params: RollingStatsReportParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RollingSiteStatsList>(
    {
      url: `/rollingStatsReport`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const rollingStatsBreakdown = (
  params: RollingStatsBreakdownParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RollingStatsBreakdown>(
    {
      url: `/rollingStatsBreakdown`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getScheduledShifts = (
  params: GetScheduledShiftsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ScheduledShiftList>(
    {
      url: `/scheduledShifts`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getScheduledShift = (
  params: GetScheduledShiftParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ScheduledShift>(
    {
      url: `/scheduledShift`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin Only
 */
export const createScheduledShift = (
  createScheduledShift: BodyType<CreateScheduledShift>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ScheduledShift>(
    {
      url: `/scheduledShift`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createScheduledShift,
    },
    options,
  )
}

/**
 * @summary Admin Only
 */
export const modifyScheduledShift = (
  modifyScheduledShift: BodyType<ModifyScheduledShift>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ScheduledShift>(
    {
      url: `/scheduledShift`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: modifyScheduledShift,
    },
    options,
  )
}

/**
 * @summary Admin Only
 */
export const deleteScheduledShift = (
  params: DeleteScheduledShiftParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/scheduledShift`,
      method: 'DELETE',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const getSites = (
  params?: GetSitesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SiteList>(
    {
      url: `/sites`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getSite = (
  params: GetSiteParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Site>(
    {
      url: `/site`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin Only
 */
export const createSite = (
  createSite: BodyType<CreateSite>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Site>(
    {
      url: `/site`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createSite,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const modifySite = (
  modifySite: BodyType<ModifySite>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Site>(
    {
      url: `/site`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: modifySite,
    },
    options,
  )
}

/**
 * @summary Admin Only
 */
export const deleteSite = (
  params: DeleteSiteParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/site`,
      method: 'DELETE',
      params,
    },
    options,
  )
}

/**
 * @summary Admin only
 */
export const modTemplateStatus = (
  modTemplateStatusBody: BodyType<ModTemplateStatusBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/modTemplateStatus`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: modTemplateStatusBody,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const getSiteTagsWithType = (
  params?: GetSiteTagsWithTypeParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SiteTagWithType[]>(
    {
      url: `/siteTagsWithType`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Testing Env Only
 */
export const generateDummyTags = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/generateDummyTags`,
      method: 'POST',
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const getSiteTagTypes = (
  params: GetSiteTagTypesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SiteTagType[]>(
    {
      url: `/siteTagTypes`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Superuser Only
 */
export const superuserGetAllOrgs = (
  superuserGetAllOrganizationsBody: BodyType<SuperuserGetAllOrganizationsBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Organization[]>(
    {
      url: `/superuserGetAllOrgs`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: superuserGetAllOrganizationsBody,
    },
    options,
  )
}

/**
 * @summary Superuser Only
 */
export const superuserDeactivateOrg = (
  superuserGetOwnerJwtBody: BodyType<SuperuserGetOwnerJwtBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/superuserDeactivateOrg`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: superuserGetOwnerJwtBody,
    },
    options,
  )
}

/**
 * @summary Superuser Only
 */
export const superuserSetPricing = (
  superuserSetPricingBody: BodyType<SuperuserSetPricingBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/superuserSetPricing`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: superuserSetPricingBody,
    },
    options,
  )
}

/**
 * @summary Superuser Only
 */
export const superuserGetOwnerJwt = (
  superuserGetOwnerJwtBody: BodyType<SuperuserGetOwnerJwtBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LoginSuccess>(
    {
      url: `/superuserGetOwnerJwt`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: superuserGetOwnerJwtBody,
    },
    options,
  )
}

/**
 * @summary Superuser Only
 The superuser must approve all new signups
 */
export const superuserSignupVerify = (
  params: SuperuserSignupVerifyParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SuperuserSignupVerify200>(
    {
      url: `/superuserSignupVerify`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const superuserSignupVerifyConfirm = (
  superuserSignupVerifyConfirmBody: BodyType<SuperuserSignupVerifyConfirmBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData()
  formData.append('token', superuserSignupVerifyConfirmBody.token)

  return customInstance<SuperuserSignupVerifyConfirm200>(
    {
      url: `/superuserSignupVerify`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  )
}

export const getAppVersionInfo = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AppVersionInfo>(
    {
      url: `/getAppVersionInfo`,
      method: 'GET',
    },
    options,
  )
}

export const getTaskGroups = (
  params: GetTaskGroupsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TaskGroupList>(
    {
      url: `/taskGroups`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getTaskGroup = (
  params: GetTaskGroupParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TaskGroup>(
    {
      url: `/taskGroup`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin Or Site Admin Only
 */
export const createTaskGroup = (
  createTaskGroup: BodyType<CreateTaskGroup>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TaskGroup>(
    {
      url: `/taskGroup`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createTaskGroup,
    },
    options,
  )
}

/**
 * @summary Admin Or Site Admin Only
 */
export const modifyTaskGroup = (
  modifyTaskGroup: BodyType<ModifyTaskGroup>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TaskGroup>(
    {
      url: `/taskGroup`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: modifyTaskGroup,
    },
    options,
  )
}

/**
 * @summary Admin Or Site Admin Only
 */
export const deleteTaskGroup = (
  params: DeleteTaskGroupParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/taskGroup`,
      method: 'DELETE',
      params,
    },
    options,
  )
}

export const getTasks = (
  params: GetTasksParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TaskList>(
    {
      url: `/tasks`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getAnytimeTasks = (
  params: GetAnytimeTasksParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Task[]>(
    {
      url: `/getAnytimeTasks`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin & Site Admin Only
 */
export const cloneTask = (
  cloneTaskReq: BodyType<CloneTaskReq>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Task>(
    {
      url: `/cloneTask`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: cloneTaskReq,
    },
    options,
  )
}

export const getTask = (
  params: GetTaskParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Task>(
    {
      url: `/task`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin & Site Admin Only
 */
export const createTask = (
  createTask: BodyType<CreateTask>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Task>(
    {
      url: `/task`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createTask,
    },
    options,
  )
}

/**
 * @summary Admin & Site Admin Only
 */
export const modifyTask = (
  modifyTask: BodyType<ModifyTask>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Task>(
    {
      url: `/task`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: modifyTask,
    },
    options,
  )
}

/**
 * @summary Admin & Site Admin Only
 */
export const deleteTask = (
  params: DeleteTaskParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/task`,
      method: 'DELETE',
      params,
    },
    options,
  )
}

/**
 * @summary Admin Or Site Admin Only
 */
export const getTemplateReports = (
  params: GetTemplateReportsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TemplateReport[]>(
    {
      url: `/templateReports`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin Or Site Admin Only
 */
export const getTemplateReport = (
  params: GetTemplateReportParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TemplateReport>(
    {
      url: `/templateReport`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin Or Site Admin Only
 */
export const createTemplateReport = (
  createTemplateReportData: BodyType<CreateTemplateReportData>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TemplateReport>(
    {
      url: `/templateReport`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createTemplateReportData,
    },
    options,
  )
}

/**
 * @summary Admin Or Site Admin Only
 */
export const modifyTemplateReport = (
  modifyTemplateReportData: BodyType<ModifyTemplateReportData>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TemplateReport>(
    {
      url: `/templateReport`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: modifyTemplateReportData,
    },
    options,
  )
}

/**
 * @summary Admin Or Site Admin Only
 */
export const deleteTemplateReport = (
  params: DeleteTemplateReportParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/templateReport`,
      method: 'DELETE',
      params,
    },
    options,
  )
}

/**
 * @summary Admin Or Site Admin Only
 */
export const getTemplateReportDailyData = (
  params: GetTemplateReportDailyDataParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TemplateReportDailyData>(
    {
      url: `/templateReportDailyData`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin Or Site Admin Only
 */
export const getTemplateReportAggregationData = (
  params: GetTemplateReportAggregationDataParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<MultiChoiceQuestion[]>(
    {
      url: `/templateReportAggregationData`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Testing Env Only
 */
export const getMockEmails = (
  params: GetMockEmailsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<MockEmail[]>(
    {
      url: `/getMockEmails`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Testing Env Only
 */
export const getMockSMSs = (
  params: GetMockSMSsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<MockSMS[]>(
    {
      url: `/getMockSMS`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Testing Env Only
 */
export const getMockPNs = (
  params: GetMockPNsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<MockPN[]>(
    {
      url: `/getMockPNs`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Testing Env Only
 */
export const deleteTestingEnv = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/deleteTestingEnv`,
      method: 'DELETE',
    },
    options,
  )
}

/**
 * @summary Testing Env Only
 */
export const simulateDailyCron = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/simulateDailyCron`,
      method: 'POST',
    },
    options,
  )
}

/**
 * @summary Testing Env Only
 */
export const createGlobalTemplate = (
  createGlobalTemplateBody: BodyType<CreateGlobalTemplateBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/createGlobalTemplate`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createGlobalTemplateBody,
    },
    options,
  )
}

/**
 * @summary Health/Heartbeat endpoint
 */
export const healthendpoint = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<string>(
    {
      url: `/_health`,
      method: 'GET',
    },
    options,
  )
}

export const resendHook = (
  resendPayloadData: BodyType<ResendPayloadData>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/resendHook`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: resendPayloadData,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const getUsersBySite = (
  params: GetUsersBySiteParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UserList>(
    {
      url: `/users`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin
 */
export const getUsersByOrg = (
  params?: GetUsersByOrgParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UserList>(
    {
      url: `/orgUsers`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const getUserBySite = (
  params: GetUserBySiteParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/user`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const getWhoWasThere = (
  params: GetWhoWasThereParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UserOnsite[]>(
    {
      url: `/whoWasThere`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const getSiteSubscribed = (
  params: GetSiteSubscribedParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User[]>(
    {
      url: `/siteSubscribedUsers`,
      method: 'GET',
      params,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const userSitePositionMod = (
  userModPosition: BodyType<UserModPosition>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/userSitePositionMod`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userModPosition,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const userSiteMod = (
  modifyUserDetails: BodyType<ModifyUserDetails>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/userDetailsMod`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: modifyUserDetails,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const userReportMod = (
  userMod: BodyType<UserMod>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/userReportMod`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userMod,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const userWallboardLoginMod = (
  userMod: BodyType<UserMod>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/userWallboardLoginMod`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userMod,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const userSiteAdminMod = (
  userModSiteAdmin: BodyType<UserModSiteAdmin>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/userSiteAdminMod`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userModSiteAdmin,
    },
    options,
  )
}

/**
 * @summary Admin And Site Admin
 */
export const userGlobalAdminMod = (
  userModGlobalAdmin: BodyType<UserModGlobalAdmin>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<User>(
    {
      url: `/userGlobalAdminMod`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userModGlobalAdmin,
    },
    options,
  )
}

/**
 * @summary Admin or Site admin
 */
export const adminSetPassword = (
  adminSetPassword: BodyType<AdminSetPassword>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/adminSetPassword`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: adminSetPassword,
    },
    options,
  )
}

export type GetGroupedCompletionsResult = NonNullable<
  Awaited<ReturnType<typeof getGroupedCompletions>>
>
export type SiteSnapshotForUserResult = NonNullable<
  Awaited<ReturnType<typeof siteSnapshotForUser>>
>
export type SiteSnapshotForAdminResult = NonNullable<
  Awaited<ReturnType<typeof siteSnapshotForAdmin>>
>
export type GetSelfResult = NonNullable<Awaited<ReturnType<typeof getSelf>>>
export type LoginUserFResult = NonNullable<
  Awaited<ReturnType<typeof loginUserF>>
>
export type LogoutUserResult = NonNullable<
  Awaited<ReturnType<typeof logoutUser>>
>
export type RefreshUserTokenResult = NonNullable<
  Awaited<ReturnType<typeof refreshUserToken>>
>
export type AddPushTokenResult = NonNullable<
  Awaited<ReturnType<typeof addPushToken>>
>
export type RegisterResult = NonNullable<Awaited<ReturnType<typeof register>>>
export type RequestResetResult = NonNullable<
  Awaited<ReturnType<typeof requestReset>>
>
export type ConfirmResetResult = NonNullable<
  Awaited<ReturnType<typeof confirmReset>>
>
export type WallboardGetLoginTokenResult = NonNullable<
  Awaited<ReturnType<typeof wallboardGetLoginToken>>
>
export type WallboardLoginUsersResult = NonNullable<
  Awaited<ReturnType<typeof wallboardLoginUsers>>
>
export type LoginViaWallboardResult = NonNullable<
  Awaited<ReturnType<typeof loginViaWallboard>>
>
export type GetActiveBillingResult = NonNullable<
  Awaited<ReturnType<typeof getActiveBilling>>
>
export type SetPaymentMethodResult = NonNullable<
  Awaited<ReturnType<typeof setPaymentMethod>>
>
export type ModifySubscriptionStatusResult = NonNullable<
  Awaited<ReturnType<typeof modifySubscriptionStatus>>
>
export type GetCurrentPaymentMethodResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentPaymentMethod>>
>
export type GetInvoicesResult = NonNullable<
  Awaited<ReturnType<typeof getInvoices>>
>
export type CheckForBotTokenResult = NonNullable<
  Awaited<ReturnType<typeof checkForBotToken>>
>
export type AuthorizeSingeSiteReadBotResult = NonNullable<
  Awaited<ReturnType<typeof authorizeSingeSiteReadBot>>
>
export type GetBrandsResult = NonNullable<Awaited<ReturnType<typeof getBrands>>>
export type LoadCommentsByIdsResult = NonNullable<
  Awaited<ReturnType<typeof loadCommentsByIds>>
>
export type CreateCommentResult = NonNullable<
  Awaited<ReturnType<typeof createComment>>
>
export type GetUserCompletionsResult = NonNullable<
  Awaited<ReturnType<typeof getUserCompletions>>
>
export type GetFileUploadUrlResult = NonNullable<
  Awaited<ReturnType<typeof getFileUploadUrl>>
>
export type GetAllCompletionsResult = NonNullable<
  Awaited<ReturnType<typeof getAllCompletions>>
>
export type MarkCompleteResult = NonNullable<
  Awaited<ReturnType<typeof markComplete>>
>
export type CreateAnytimeCompletionResult = NonNullable<
  Awaited<ReturnType<typeof createAnytimeCompletion>>
>
export type MarkProcessingResult = NonNullable<
  Awaited<ReturnType<typeof markProcessing>>
>
export type CompletionEvalResult = NonNullable<
  Awaited<ReturnType<typeof completionEval>>
>
export type AllContactsResult = NonNullable<
  Awaited<ReturnType<typeof allContacts>>
>
export type GetContactsBySiteResult = NonNullable<
  Awaited<ReturnType<typeof getContactsBySite>>
>
export type GetContactResult = NonNullable<
  Awaited<ReturnType<typeof getContact>>
>
export type CreateContactResult = NonNullable<
  Awaited<ReturnType<typeof createContact>>
>
export type ModifyContactResult = NonNullable<
  Awaited<ReturnType<typeof modifyContact>>
>
export type DeleteContactResult = NonNullable<
  Awaited<ReturnType<typeof deleteContact>>
>
export type GetEmailPrefsResult = NonNullable<
  Awaited<ReturnType<typeof getEmailPrefs>>
>
export type ModEmailPrefsResult = NonNullable<
  Awaited<ReturnType<typeof modEmailPrefs>>
>
export type GetEscalationsResult = NonNullable<
  Awaited<ReturnType<typeof getEscalations>>
>
export type GetEscalationResult = NonNullable<
  Awaited<ReturnType<typeof getEscalation>>
>
export type CreateEscalationResult = NonNullable<
  Awaited<ReturnType<typeof createEscalation>>
>
export type ModifyEscalationResult = NonNullable<
  Awaited<ReturnType<typeof modifyEscalation>>
>
export type DeleteEscalationResult = NonNullable<
  Awaited<ReturnType<typeof deleteEscalation>>
>
export type GetEscOccurrencesResult = NonNullable<
  Awaited<ReturnType<typeof getEscOccurrences>>
>
export type ResolveEscOccurrenceResult = NonNullable<
  Awaited<ReturnType<typeof resolveEscOccurrence>>
>
export type EmailEscOccurrenceResult = NonNullable<
  Awaited<ReturnType<typeof emailEscOccurrence>>
>
export type SendTestEmailToExternalResult = NonNullable<
  Awaited<ReturnType<typeof sendTestEmailToExternal>>
>
export type HandleGeofenceResult = NonNullable<
  Awaited<ReturnType<typeof handleGeofence>>
>
export type MarkSubscribedResult = NonNullable<
  Awaited<ReturnType<typeof markSubscribed>>
>
export type MarkUnsubscribedResult = NonNullable<
  Awaited<ReturnType<typeof markUnsubscribed>>
>
export type PushTokenTestResult = NonNullable<
  Awaited<ReturnType<typeof pushTokenTest>>
>
export type GetOrganizationResult = NonNullable<
  Awaited<ReturnType<typeof getOrganization>>
>
export type ModifyOrganizationResult = NonNullable<
  Awaited<ReturnType<typeof modifyOrganization>>
>
export type AllOrgTemplatesResult = NonNullable<
  Awaited<ReturnType<typeof allOrgTemplates>>
>
export type UseOrgTemplateResult = NonNullable<
  Awaited<ReturnType<typeof useOrgTemplate>>
>
export type RemoveOrgTemplateResult = NonNullable<
  Awaited<ReturnType<typeof removeOrgTemplate>>
>
export type GetPositionsResult = NonNullable<
  Awaited<ReturnType<typeof getPositions>>
>
export type GetPositionResult = NonNullable<
  Awaited<ReturnType<typeof getPosition>>
>
export type CreatePositionResult = NonNullable<
  Awaited<ReturnType<typeof createPosition>>
>
export type ModifyPositionResult = NonNullable<
  Awaited<ReturnType<typeof modifyPosition>>
>
export type DeletePositionResult = NonNullable<
  Awaited<ReturnType<typeof deletePosition>>
>
export type ConductReportResult = NonNullable<
  Awaited<ReturnType<typeof conductReport>>
>
export type RollingStatsReportResult = NonNullable<
  Awaited<ReturnType<typeof rollingStatsReport>>
>
export type RollingStatsBreakdownResult = NonNullable<
  Awaited<ReturnType<typeof rollingStatsBreakdown>>
>
export type GetScheduledShiftsResult = NonNullable<
  Awaited<ReturnType<typeof getScheduledShifts>>
>
export type GetScheduledShiftResult = NonNullable<
  Awaited<ReturnType<typeof getScheduledShift>>
>
export type CreateScheduledShiftResult = NonNullable<
  Awaited<ReturnType<typeof createScheduledShift>>
>
export type ModifyScheduledShiftResult = NonNullable<
  Awaited<ReturnType<typeof modifyScheduledShift>>
>
export type DeleteScheduledShiftResult = NonNullable<
  Awaited<ReturnType<typeof deleteScheduledShift>>
>
export type GetSitesResult = NonNullable<Awaited<ReturnType<typeof getSites>>>
export type GetSiteResult = NonNullable<Awaited<ReturnType<typeof getSite>>>
export type CreateSiteResult = NonNullable<
  Awaited<ReturnType<typeof createSite>>
>
export type ModifySiteResult = NonNullable<
  Awaited<ReturnType<typeof modifySite>>
>
export type DeleteSiteResult = NonNullable<
  Awaited<ReturnType<typeof deleteSite>>
>
export type ModTemplateStatusResult = NonNullable<
  Awaited<ReturnType<typeof modTemplateStatus>>
>
export type GetSiteTagsWithTypeResult = NonNullable<
  Awaited<ReturnType<typeof getSiteTagsWithType>>
>
export type GenerateDummyTagsResult = NonNullable<
  Awaited<ReturnType<typeof generateDummyTags>>
>
export type GetSiteTagTypesResult = NonNullable<
  Awaited<ReturnType<typeof getSiteTagTypes>>
>
export type SuperuserGetAllOrgsResult = NonNullable<
  Awaited<ReturnType<typeof superuserGetAllOrgs>>
>
export type SuperuserDeactivateOrgResult = NonNullable<
  Awaited<ReturnType<typeof superuserDeactivateOrg>>
>
export type SuperuserSetPricingResult = NonNullable<
  Awaited<ReturnType<typeof superuserSetPricing>>
>
export type SuperuserGetOwnerJwtResult = NonNullable<
  Awaited<ReturnType<typeof superuserGetOwnerJwt>>
>
export type SuperuserSignupVerifyResult = NonNullable<
  Awaited<ReturnType<typeof superuserSignupVerify>>
>
export type SuperuserSignupVerifyConfirmResult = NonNullable<
  Awaited<ReturnType<typeof superuserSignupVerifyConfirm>>
>
export type GetAppVersionInfoResult = NonNullable<
  Awaited<ReturnType<typeof getAppVersionInfo>>
>
export type GetTaskGroupsResult = NonNullable<
  Awaited<ReturnType<typeof getTaskGroups>>
>
export type GetTaskGroupResult = NonNullable<
  Awaited<ReturnType<typeof getTaskGroup>>
>
export type CreateTaskGroupResult = NonNullable<
  Awaited<ReturnType<typeof createTaskGroup>>
>
export type ModifyTaskGroupResult = NonNullable<
  Awaited<ReturnType<typeof modifyTaskGroup>>
>
export type DeleteTaskGroupResult = NonNullable<
  Awaited<ReturnType<typeof deleteTaskGroup>>
>
export type GetTasksResult = NonNullable<Awaited<ReturnType<typeof getTasks>>>
export type GetAnytimeTasksResult = NonNullable<
  Awaited<ReturnType<typeof getAnytimeTasks>>
>
export type CloneTaskResult = NonNullable<Awaited<ReturnType<typeof cloneTask>>>
export type GetTaskResult = NonNullable<Awaited<ReturnType<typeof getTask>>>
export type CreateTaskResult = NonNullable<
  Awaited<ReturnType<typeof createTask>>
>
export type ModifyTaskResult = NonNullable<
  Awaited<ReturnType<typeof modifyTask>>
>
export type DeleteTaskResult = NonNullable<
  Awaited<ReturnType<typeof deleteTask>>
>
export type GetTemplateReportsResult = NonNullable<
  Awaited<ReturnType<typeof getTemplateReports>>
>
export type GetTemplateReportResult = NonNullable<
  Awaited<ReturnType<typeof getTemplateReport>>
>
export type CreateTemplateReportResult = NonNullable<
  Awaited<ReturnType<typeof createTemplateReport>>
>
export type ModifyTemplateReportResult = NonNullable<
  Awaited<ReturnType<typeof modifyTemplateReport>>
>
export type DeleteTemplateReportResult = NonNullable<
  Awaited<ReturnType<typeof deleteTemplateReport>>
>
export type GetTemplateReportDailyDataResult = NonNullable<
  Awaited<ReturnType<typeof getTemplateReportDailyData>>
>
export type GetTemplateReportAggregationDataResult = NonNullable<
  Awaited<ReturnType<typeof getTemplateReportAggregationData>>
>
export type GetMockEmailsResult = NonNullable<
  Awaited<ReturnType<typeof getMockEmails>>
>
export type GetMockSMSsResult = NonNullable<
  Awaited<ReturnType<typeof getMockSMSs>>
>
export type GetMockPNsResult = NonNullable<
  Awaited<ReturnType<typeof getMockPNs>>
>
export type DeleteTestingEnvResult = NonNullable<
  Awaited<ReturnType<typeof deleteTestingEnv>>
>
export type SimulateDailyCronResult = NonNullable<
  Awaited<ReturnType<typeof simulateDailyCron>>
>
export type CreateGlobalTemplateResult = NonNullable<
  Awaited<ReturnType<typeof createGlobalTemplate>>
>
export type HealthendpointResult = NonNullable<
  Awaited<ReturnType<typeof healthendpoint>>
>
export type ResendHookResult = NonNullable<
  Awaited<ReturnType<typeof resendHook>>
>
export type GetUsersBySiteResult = NonNullable<
  Awaited<ReturnType<typeof getUsersBySite>>
>
export type GetUsersByOrgResult = NonNullable<
  Awaited<ReturnType<typeof getUsersByOrg>>
>
export type GetUserBySiteResult = NonNullable<
  Awaited<ReturnType<typeof getUserBySite>>
>
export type GetWhoWasThereResult = NonNullable<
  Awaited<ReturnType<typeof getWhoWasThere>>
>
export type GetSiteSubscribedResult = NonNullable<
  Awaited<ReturnType<typeof getSiteSubscribed>>
>
export type UserSitePositionModResult = NonNullable<
  Awaited<ReturnType<typeof userSitePositionMod>>
>
export type UserSiteModResult = NonNullable<
  Awaited<ReturnType<typeof userSiteMod>>
>
export type UserReportModResult = NonNullable<
  Awaited<ReturnType<typeof userReportMod>>
>
export type UserWallboardLoginModResult = NonNullable<
  Awaited<ReturnType<typeof userWallboardLoginMod>>
>
export type UserSiteAdminModResult = NonNullable<
  Awaited<ReturnType<typeof userSiteAdminMod>>
>
export type UserGlobalAdminModResult = NonNullable<
  Awaited<ReturnType<typeof userGlobalAdminMod>>
>
export type AdminSetPasswordResult = NonNullable<
  Awaited<ReturnType<typeof adminSetPassword>>
>
